<template>
  <div class="about">
    <div class="head">
      <span style="float: left; line-height: 55px">
        <i class="el-icon-arrow-left" @click="$router.back(-1)"></i
        >商品列表</span
      >
      <div id="box" ref="box">
        <div
          class="marquee-box"
          ref="marquee"
          @mouseover="menter"
          @mouseleave="mleave"
        >
          <p ref="cmdlist" id="pWidth" @click="detail(i)">
            <i class="el-icon-s-flag" style="color: red"></i>{{ gg }}
          </p>
        </div>
      </div>
      <img :src="imge" width="40" height="40" class="touimg" />
      <!-- <el-card class="notice">
        <el-carousel
          indicator-position="none"
          arrow="never"
          direction="horizontal"
          :interval="6000"
          :loop="true"
        >
          <el-carousel-item v-for="(i, ind) in newlist" :key="ind" class="notice_item">
            <i class="el-icon-s-flag" style="color: red"></i
            ><span @click="detail(i)" style="cursor: pointer"> {{ i.title }}</span>
          </el-carousel-item>
        </el-carousel>
      </el-card> -->
      <h2 class="spname">{{ shopname }}[{{ dptype }}]</h2>
    </div>
    <el-date-picker
      v-model="start"
      type="date"
      placeholder="开始日期"
      format="yyyy 年 MM 月 dd 日"
      value-format="yyyy-MM-dd"
    >
    </el-date-picker>
    至
    <el-date-picker
      v-model="end"
      type="date"
      placeholder="结束日期"
      format="yyyy 年 MM 月 dd 日"
      value-format="yyyy-MM-dd"
    >
    </el-date-picker>
    <label for="" style="margin-left: 20px"> 商品名：</label>
    <el-input
      placeholder="请输入内容"
      v-model="nameinput"
      style="width: 200px"
      clearable
    >
    </el-input>
    商品类型：
    <el-select v-model="status" @change="zt">
      <el-option :name="0" label="全部" :value="0"> </el-option>
      <el-option
        v-for="item in option"
        :key="item.cate_id"
        :name="item.cate_id"
        :label="item.cate_name"
        :value="item.cate_id"
      >
      </el-option>
    </el-select>
    <el-button type="warning" @click="getUser" style="margin-left: 20px"
      >查看</el-button
    >
    <el-button type="warning" @click="handleExcel" style="margin-left: 10px"
      >导出</el-button
    >
    <br /><br />
    <!-- 内容区域 -->
    <el-main id="main" class="main">
      <Breadcrumb></Breadcrumb>
      <h5>零售汇总：{{ lingshou }}元</h5>
      <h5>退货汇总：{{ tuihuo }} 元</h5>
      <h5>纯利润：{{ lirun }}元</h5>
      <h6>总库存：{{ kucun }}</h6>
      <h6>销量：{{ xiaoliang }}</h6>
      <h6>退货：{{ tuihuonum }}</h6>
      <h6>实际销量：{{ sjxl }}</h6>
      <!-- 表格 -->
      <div class="table">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :default-sort="{ prop: 'date', order: 'descending' }"
          @sort-change="changeSort"
        >
          <el-table-column fixed prop="goods_id" label="ID"> </el-table-column>
          <el-table-column prop="title" label="商品名称"> </el-table-column>
          <el-table-column property="photo" label="商品图片">
            <template slot-scope="scope">
              <img :src="scope.row.photo" width="40" height="40" />
            </template>
          </el-table-column>
          <el-table-column prop="num" label="库存"> </el-table-column>
          <el-table-column prop="guige" label="规格"> </el-table-column>
          <el-table-column prop="price" label="易货额"> </el-table-column>
          <el-table-column prop="xj" label="易货现金"> </el-table-column>
          <el-table-column prop="zk_xj" label="折扣现金"> </el-table-column>
          <el-table-column prop="give_integral" label="赠送积分">
          </el-table-column>
          <el-table-column prop="nickname" label="入库人"> </el-table-column>
          <el-table-column prop="create_time" label="入库时间">
          </el-table-column>
          <el-table-column prop="goodsnum_init" label="初始库存">
          </el-table-column>
          <el-table-column prop="sale_num" label="出库" sortable="custom">
            <template slot-scope="scope">
              <router-link
                :to="{
                  path: '/outlist',
                  query: { id: scope.row.goods_id },
                }"
                class="a"
              >
                <el-button type="info">{{ scope.row.sale_num }}</el-button>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="dh_num" label="调货">
            <template slot-scope="scope">
              <router-link
                :to="{
                  path: '/diaolist',
                  query: { id: scope.row.goods_id },
                }"
                class="a"
              >
                <el-button type="info">{{ scope.row.dh_num }}</el-button>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="medium"
                >查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-dialog title=" " :visible.sync="visible" width="90%">
          操作类型：
          <el-select v-model="statuss" placeholder="商品操作" @change="zts">
            <el-option
              v-for="items in options"
              :key="items.value"
              :name="items.value"
              :label="items.label"
              :value="items.value"
            >
            </el-option>
          </el-select>
          <el-table :data="gridData" height="520">
            <el-table-column property="id" label="id"></el-table-column>
            <el-table-column property="nickname" label="姓名"></el-table-column>
            <el-table-column prop="ynum" label="更改前"> </el-table-column>
            <el-table-column prop="change_num" label="改变值">
            </el-table-column>
            <el-table-column property="num" label="更改后"></el-table-column>
            <el-table-column
              label="操作类型"
              prop="optype"
              :formatter="stateFormat"
            ></el-table-column>
            <el-table-column prop="ddd" label="时间"> </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChanges"
              @current-change="handleCurrentChanges"
              :current-page="currentPage"
              :page-sizes="[20, 40, 60]"
              :page-size="pagesizes"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totals"
            >
            </el-pagination>
          </div>
        </el-dialog>
      </div>
      <!-- /End 表格 -->
      <!-- <div>
				<el-popover placement="left" width="780px" trigger="manual" :offset="300" v-model="visible">
					<div class="spbiao">
						<el-button slot="reference" @click="visible = !visible" style="float:right"><i class="el-icon-close"></i></el-button>
						<el-table :data="gridData" height="520">
							<el-table-column property="id" label="编码"></el-table-column>
							<el-table-column property="title" label="名称"></el-table-column>
							<el-table-column property="photo" label="商品">
								<template slot-scope="scope">
									<img :src="scope.row.photo" width="40" height="40">
								</template>
							</el-table-column>
							<el-table-column property="price" label="单价"></el-table-column>
							<el-table-column property="num" label="数量"></el-table-column>
							<el-table-column property="total_price" label="总价"></el-table-column>
						</el-table>

					</div>
				</el-popover>
			</div> -->

      <!-- <Pagination></Pagination> -->

      <!-- 分页 -->
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[100, 300, 500]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- /End 分页 -->
    </el-main>
    <!-- /End 内容区域 -->
  </div>
</template>

<script>
// import axios from "axios";
export default {
  name: "splist",
  data() {
    return {
      start: "",
      end: "",
      nameinput: "",
      gridData: [],
      imge: "",
      shopname: "",
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false,
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      totals: 0, // 总条目数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 100, // 每页显示条数
      pagenums: 1, // 当前页码
      pagesizes: 100, // 每页显示条数
      dptype: "",
      option: [],
      options: [
        {
          value: "1",
          label: "添加库存",
        },
        {
          value: "2",
          label: "设置价格",
        },
        {
          value: "3",
          label: "设置现金",
        },
        {
          value: "4",
          label: "客户消费",
        },
      ],
      sid: "",
      statuss: "", //状态
      statusid: "",
      goodsid: "",
      id: "",
      status: "", //状态
      lingshou: 0,
      tuihuo: 0,
      lirun: 0,
      kucun: 0,
      xiaoliang: 0,
      tuihuonum: 0,
      sjxl: 0,
      newlist: [],
      sale_num_order: "",
      timer: "", //计时器
      pwidth: 0, //公告文本的宽度
      value: 0,
      gg: "",
      i: "",
      titleStr: "",
      // windowWidth:'', // box宽度
      windowWidth: document.documentElement.clientWidth / 2.5,
    };
  },
  mounted() {
    let element = this.$refs.cmdlist;
    this.pwidth = document.defaultView
      .getComputedStyle(element, "")
      .width.split("px");
    this.timer = setInterval(this.clickCommend, 20);
    this.getUser();
    this.shopname = localStorage.getItem("shopname");
    this.imge = localStorage.getItem("imge");
    var type_id = localStorage.getItem("type_id");
    if (type_id == 3) {
      this.dptype = "易货店";
    } else if (type_id == 5) {
      this.dptype = "折扣仓";
    }
    this.$axios.post(this.$api.gooslist, {}).then((res) => {
      this.option = res.data; // 表格数据
    });
    this.$axios.post(this.$api.new, {}).then((res) => {
      if (res.code == 1) {
        this.newlist = res.data;
        this.gg = this.newlist[0].title;
        this.i = 0;
      }
    });
    // this.$axios.post(this.$api.shopinfo, {}).then((res) => {
    //   if (res.code == 1) {
    //     console.log(res.data.shop_name);
    //     this.shopname = res.data.shop_name;
    //     this.imge = res.data.logo;
    //     // this.sdyingshou = res.data.total_price
    //     // this.sdshishou = res.data.total_price
    //   }
    // });
  },
  watch: {
    value(newValue) {
      let allWidth = parseInt(this.windowWidth) + parseInt(this.pwidth[0]);
      // console.log(oldValue);
      if (newValue <= -allWidth) {
        this.$refs.cmdlist.style.marginLeft = this.windowWidth + "px";
        this.value = 0;
      }
    },
  },
  methods: {
    handleExcel() {
      let fields = {
        title0: "ID",
        title1: "初始库存",
        title2: "商品名",
        title3: "数量",
        title4: "规格",
        title5: "赠送积分",
        title6: "易货额",
        title7: "易货现金",
        title8: "折扣现金",
        title9: "时间",
        title10: "入库员",
      };
      let arry = [];
      let params = {};
      params.page = 1;
      params.pageSize = 999; //这里也可以直接通过接口返回的total，拿值
      this.$axios
        .post(this.$api.daogoods, {
          title: this.input,
          // shop_id: this.sid,
          start_time: this.start,
          end_time: this.end,
        })
        .then((res) => {
          console.log(res);
          res.data.forEach((ele) => {
            arry.push({
              title0: ele.goods_id,
              title1: ele.goodsnum_init,
              title2: ele.title,
              title3: ele.num,
              title4: ele.guige,
              title5: ele.give_integral,
              title6: ele.price,
              title7: ele.xj,
              title8: ele.zk_xj,
              title9: ele.create_time,
              title10: ele.nickname,
            });
          });
          console.log(arry);
          console.log(fields);
          this.$xlsx.xlsx(arry, fields, "excel统计列表");
        });
    },
    clickCommend() {
      // console.log(e);
      let _this = this;
      this.$nextTick(() => {
        this.value -= 1;
        this.$refs.cmdlist.style.marginLeft =
          _this.windowWidth + this.value + "px";
      });
    },
    menter() {
      clearInterval(this.timer);
    },
    mleave() {
      this.timer = setInterval(this.clickCommend, 20);
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    detail(i) {
      this.$router.push({
        name: "proinfo",
        params: {
          details: this.newlist[i].details,
          title: this.newlist[i].title,
        },
      });
    },
    stateFormat(row) {
      if (row.optype === 1) {
        return "添加库存";
      } else if (row.optype === 2) {
        return "设置价格";
      } else if (row.optype === 3) {
        return "设置现金";
      } else if (row.optype === 4) {
        return "客户消费";
      }
    },
    zts(data) {
      this.statusid = data; //操作类型
      console.log(this.statusid);
      this.spcz();
    },
    zt(data) {
      this.id = data;
      console.log(this.id);
    },
    changeSort(val) {
      console.log(val.prop);
      console.log(val.order); // column: {…} order: "ascending" prop: "date"
      if (val.prop == "sale_num") {
        if (val.order == "ascending") {
          this.sale_num_order = "asc";
          this.getUser();
        } else if (val.order == "descending") {
          this.sale_num_order = "desc";
          this.getUser();
        }
      }
    },
    // checkTime(i){
    //   if(i<10){
    //     i='0'+i
    //     }
    //     return i
    //     },
    getUser() {
      console.log(this.$route.query.id);
      this.$axios
        .post(this.$api.splist, {
          title: this.nameinput,
          start_time: this.start,
          end_time: this.end,
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
          cate_id: this.id,
          sale_num_order: this.sale_num_order,
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.count; // 总条目数
          this.lingshou = res.data.total_income; //零售汇总
          this.tuihuo = res.data.total_refund; //退货汇总
          this.lirun = res.data.total_profit; //利润
          this.kucun = res.data.total_goods_num; //库存
          this.xiaoliang = res.data.total_salenum; //销量
          this.tuihuonum = res.data.refund_num; //退货量
          this.sjxl = res.data.actual_salenum; //实际销量
        });
    },
    spcz() {
      this.$axios
        .post(this.$api.spcz, {
          goods_id: this.goodsid,
          page: this.pagenums, // 当前页码
          page_size: this.pagesizes, // 每页显示条数
          optype: this.statusid, //操作类型
        })
        .then((res) => {
          this.visible = true;
          console.log(res);
          this.gridData = res.data.list; // 表格数据
          this.totals = res.data.count; // 总条目数
        });
    },
    handleClick(row) {
      console.log(row);
      this.goodsid = row.goods_id; //商品ID
      this.spcz();
    },
    handleSizeChanges(val) {
      //改变时
      this.pagesizes = val;
      this.spcz();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChanges(val) {
      //条目改变时
      this.pagenums = val;
      this.spcz();
      console.log(`当前页: ${val}`);
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
#box {
  width: 60%;
  vertical-align: middle;
  display: inline-block;
  margin-right: 5%;
  background: #50a3e5;
  color: #fff;
  white-space: nowrap;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.marquee-box {
  overflow: auto;
}
#pWidth {
  width: 60%;
  height: 50px;
  padding: 0;
  margin: 0;
  line-height: 50px;
  display: block;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  font-family: 微软雅黑;
  font-size: 14px;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.spbiao {
  width: 70%;
  left: 12%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}
.head {
  text-align: right;
  background: #50a3e5;
  color: white;
  padding: 10px;
  margin: 15px;
}
.touimg {
  width: 45px;
  height: 45px;
  vertical-align: middle;
  border-radius: 50%;
}
.spname {
  font-size: 18px;
  display: inline-block;
  margin-left: 15px;
}
h5 {
  display: inline-block;
  width: 30%;
}
h6 {
  display: inline-block;
  width: 25%;
}
.notice {
  width: 65%;
  height: 50px;
  top: 25px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}
.notice /deep/ .el-card__body {
  padding: 0;
}
/*使文字和公告图片在一行*/
.notice_item {
  height: 50px;
  line-height: 50px;
  background: #50a3e5;
  color: white;
  white-space: nowrap;
  font-size: 14px;
  /* background-color: #fff; */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.notice_item img {
  width: 40px;
  /*height: 16px;*/
  margin: 0 44px 0 32px;
}
.el-card {
  border: none;
}
</style>
