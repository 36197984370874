<template>
  <div class="about">
    <div class="head">
      <span style="float: left; line-height: 55px">
        <i class="el-icon-arrow-left" @click="$router.back(-1)"></i>预约订单</span
      >
      <div id="box" ref="box">
        <div class="marquee-box" ref="marquee" @mouseover="menter" @mouseleave="mleave">
          <p ref="cmdlist" id="pWidth" @click="detail(i)">
            <i class="el-icon-s-flag" style="color: red"></i>{{ gg }}
          </p>
        </div>
      </div>
      <img :src="imge" width="40" height="40" class="touimg" />
      <!-- <el-card class="notice">
        <el-carousel
          indicator-position="none"
          arrow="never"
          direction="horizontal"
          :interval="6000"
          :loop="true"
        >
          <el-carousel-item v-for="(i, ind) in newlist" :key="ind" class="notice_item">
            <i class="el-icon-s-flag" style="color: red"></i
            ><span @click="detail(i)" style="cursor: pointer"> {{ i.title }}</span>
          </el-carousel-item>
        </el-carousel>
      </el-card> -->
      <h2 class="spname">{{ shopname }}[{{ dptype }}]</h2>
    </div>
    <el-date-picker
      v-model="start"
      type="date"
      placeholder="开始日期"
      format="yyyy 年 MM 月 dd 日"
      value-format="yyyy-MM-dd"
      style="width: 100px"
    >
    </el-date-picker>
    至
    <el-date-picker
      v-model="end"
      type="date"
      placeholder="结束日期"
      format="yyyy 年 MM 月 dd 日"
      value-format="yyyy-MM-dd"
      style="width: 100px"
    >
    </el-date-picker>
    <label for="" style="margin-left: 20px">预约人手机号：</label>
    <el-input placeholder="请输入内容" v-model="input" style="width: 120px" clearable>
    </el-input>
    <label for="" style="margin-left: 20px">预约人姓名：</label>
    <el-input placeholder="请输入内容" v-model="nameinput" style="width: 100px" clearable>
    </el-input>
    状态：
    <el-select v-model="status" placeholder="状态" @change="zt">
      <el-option
        v-for="item in option"
        :key="item.value"
        :name="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-button type="warning" @click="getUser" style="margin-left: 20px">查看</el-button>
    <br /><br />
    <!-- 内容区域 -->
    <el-main id="main" class="main">
      <Breadcrumb></Breadcrumb>
      <div style="text-align: left">
        <h5>零售汇总：{{ lingshou }}元</h5>
        <h5>退货汇总：{{ tuihuo }} 元</h5>
        <h5>纯利润：{{ lirun }}元</h5>
        <!-- <el-input v-model="lingshou" placeholder="请输入内容" style="width: 200px;" ref='sd' id="sd" disabled></el-input> -->
        <!-- <el-input v-model="tuihuo" placeholder="请输入内容" style="width: 200px;" disabled></el-input> -->
      </div>
      <!-- 表格 -->
      <div class="table">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column fixed prop="id" label="ID"> </el-table-column>
          <el-table-column prop="nickname" label="用户名"> </el-table-column>
          <el-table-column prop="account" label="电话"> </el-table-column>
          <el-table-column prop="create_time" label="结账时间"> </el-table-column>
          <el-table-column prop="total_price" label="总金额"> </el-table-column>
          <el-table-column prop="total_xj" label="总现金"> </el-table-column>
          <el-table-column prop="total_zkxj" label="总折扣现金"> </el-table-column>
          <el-table-column prop="total_integral" label="总积分"> </el-table-column>
          <el-table-column
            label="状态"
            prop="status"
            :formatter="stateFormat"
          ></el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row, scope.$index)"
                type="text"
                size="medium"
                >查看</el-button
              >
              <el-button @click="jz(scope.row, scope.$index)" type="text" size="medium"
                >结账</el-button
              >
               <el-button @click="deal(scope.row, scope.$index)" type="text" size="medium"
                >已处理</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- /End 表格 -->
      <div>
        <el-dialog title="" :visible.sync="visible" width="90%">
          <el-table :data="gridData" height="520">
            <el-table-column property="goods_id" label="编码"></el-table-column>
            <el-table-column property="title" label="名称"></el-table-column>
            <el-table-column property="photo" label="商品">
              <template slot-scope="scope">
                <img :src="scope.row.photo" @error="defImg()" width="40" height="40" />
              </template>
            </el-table-column>
            <el-table-column property="price" label="原价"></el-table-column>
            <el-table-column property="give_integral" label="赠送积分"></el-table-column>
            <el-table-column property="goods_num" label="数量"></el-table-column>
            <el-table-column property="xj" label="现金"></el-table-column>
            <el-table-column property="zk_xj" label="折扣现金"></el-table-column>
          </el-table>
        </el-dialog>
      </div>
      <el-dialog title=" " :visible.sync="visibles" :before-close="handleClose">
        <div>
          <div style="margin-top: 20px; text-align: left">
            选择卡号：
            <el-select v-model="fangshi" placeholder="卡号" @change="ka">
              <el-option
                v-for="item in options"
                :key="item.id"
                :name="item.id"
                :label="item.cardnum"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <p>
              <span class="xinxi">类型：{{ leixing }}</span
              ><span class="xinxi">余额：{{ yue }}</span>
            </p>
            <p v-show="but == 2">
              现金支付方式：
              <el-radio v-model="radio" label="1" @change="changeRadio($event)"
                >线上支付</el-radio
              >
              <el-radio v-model="radio" label="2" @change="changeRadio($event)"
                >线下收取</el-radio
              >
              <!-- <span class="xinxi">票据： 1张</span> 支付方式：
            <el-select v-model="fangshi" placeholder="储值卡" @change="zt">
              <el-option
                v-for="(itema, indea) in options"
                :key="indea"
                :name="itema.value"
                :label="itema.label"
                :value="itema.value"
              >
              </el-option>
            </el-select> -->
            </p>
            易货额度：
            <el-input
              v-model="yingshou"
              placeholder="请输入内容"
              style="width: 150px"
              @keyup.enter.native="searchEnterFunyingshou"
              disabled
              >{{ yingshou }}</el-input
            >
            应收现金：
            <el-input
              v-model="zhifuxj"
              placeholder="请输入内容"
              style="width: 150px"
              @keyup.enter.native="searchEnterFunzhifu"
              disabled
              >{{ zhifuxj }}</el-input
            ><br />
            <div v-show="buput == 1" style="margin: 3% 0">
              微信扫码：
              <el-input
                type="text"
                v-model="smxianjin"
                placeholder="点击后扫微信付款码支付"
                style="width: 150px"
                @focus="changeColor"
                @keyup.enter.native="searchEnterFunpay"
                autofocus
                clearable
              ></el-input>
              <br /><span style="color: red">*点击输入框后扫描微信付款码支付*</span>
            </div>
            <el-button
              type="success"
              @click="xianxia"
              v-show="buput == 2"
              style="margin: 5%"
              >线下结算</el-button
            >
          </div>
          <el-button type="success" @click="jiesuan" v-show="but == 1" style="margin: 5%"
            >结算</el-button
          >
          <el-button :plain="true" @click="nopay" v-show="but == 1" style="margin: 5%"
            >取消</el-button
          >
        </div>
      </el-dialog>
      <!-- <Pagination></Pagination> -->

      <!-- 分页 -->
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[100, 300, 500]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- /End 分页 -->
    </el-main>
    <!-- /End 内容区域 -->
  </div>
</template>

<script>
import axios from "axios";
import { Loading } from "element-ui";
export default {
  name: "appointment",
  data() {
    return {
      start: "",
      defaultImg: require("../../static/1.png"),
      end: "",
      input: "",
      syinput: "",
      cardinput: "",
      nameinput: "",
      gridData: [],
      imge: "",
      lingshou: 0,
      tuihuo: 0,
      lirun: 0,
      shopname: "",
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false,
      visibles: false, //结账弹框
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 100, // 每页显示条数
      dptype: "",
      newlist: [],
      timer: "", //计时器
      pwidth: 0, //公告文本的宽度
      value: 0,
      gg: "",
      i: "",
      titleStr: "",
      windowWidth: document.documentElement.clientWidth / 2.5,
      but: 3, //结算按钮
      buput: 0,
      radio: "1",
      smxianjin: "扫码",
      zhifuxj: 0,
      yingshou: 0,
      status: "", //状态
      statusid: "",
      option: [
        {
          value: "1",
          label: "已处理",
        },
        {
          value: "0",
          label: "未处理",
        },
      ],
      fangshi: "", //选择银行卡
      options: [],
      cardid: "",
      yue: 0,
      leixing: "",
      leixingid: 0,
      fangshiid: "",
      xianjinzk: "",
      xianjinyh: "",
      type_id: "",
    };
  },
  mounted() {
    let element = this.$refs.cmdlist;
    this.pwidth = document.defaultView.getComputedStyle(element, "").width.split("px");
    this.timer = setInterval(this.clickCommend, 20);
    this.getUser();
    this.shopname = localStorage.getItem("shopname");
    this.imge = localStorage.getItem("imge");
    this.type_id = localStorage.getItem("type_id");
    if (this.type_id == 3) {
      this.dptype = "易货店";
    } else if (this.type_id == 5) {
      this.dptype = "折扣仓";
    }
    this.$axios.post(this.$api.new, {}).then((res) => {
      if (res.code == 1) {
        this.newlist = res.data;
        this.gg = this.newlist[0].title;
        this.i = 0
      }
    });
  },
  watch: {
    value(newValue, oldValue) {
      let allWidth = parseInt(this.windowWidth) + parseInt(this.pwidth[0]);
      console.log(oldValue);
      if (newValue <= -allWidth) {
        this.$refs.cmdlist.style.marginLeft = this.windowWidth + "px";
        this.value = 0;
      }
    },
  },
  methods: {
    handleClose() {
      // alert(1111)
      location.reload(); //刷新页面
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => {});
    },
    zt(data) {
      this.statusid = data;
    },
    ka(data) {
      var obj = {};
      obj = this.options.find(function (item) {
        return item.id === data;
      });
      this.yue = obj.money;
      this.leixingid = obj.card_type;
      if (this.leixingid == 3) {
        this.leixing = "易货卡";
      } else {
        this.leixing = "折扣卡";
      }
      this.cardid = data;
      if (this.type_id == 3 && this.leixingid == 5) {
        this.zhifuxj = this.xianjinzk;
      } else {
        this.zhifuxj = this.xianjinyh;
      }
      if (this.zhifuxj > 0) {
        this.but = 2; //按钮隐藏
        this.buput = 1;
      } else {
        this.but = 1; //按钮显示
        this.buput = 3; //收款输入框隐藏
      }
    },
    clickCommend(e) {
      console.log(e);
      let _this = this;
      this.$nextTick(() => {
        this.value -= 1;
        this.$refs.cmdlist.style.marginLeft = _this.windowWidth + this.value + "px";
      });
    },
    menter() {
      clearInterval(this.timer);
    },
    mleave() {
      this.timer = setInterval(this.clickCommend, 20);
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
   detail(i) {
      this.$router.push({
        name: "proinfo",
        params: { details: this.newlist[i].details, title: this.newlist[i].title },
      });
    },
    defImg() {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
    cha() {
      this.pagenum = 1;
      this.getUser();
    },
    getUser() {
      this.$axios
        .post(this.$api.yuyue, {
          account: this.input,
          nickname: this.nameinput,
          status: this.statusid,
          start_time: this.start,
          end_time: this.end,
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.count; // 总条目数
          // this.lingshou = res.data.total_income; //零售汇总
          // this.tuihuo = res.data.total_refund; //退货汇总
          // this.lirun = res.data.total_profit; //利润
        });
    },
    stateFormat(row) {
      if (row.status === 1) {
        return "已处理";
      } else {
        return "未处理";
      }
    },
    handleClick(row, index) {
      console.log(row);
      this.visible = true;
      this.gridData = this.tableData[index].view;
    },
    deal(row,index){
       console.log(row);
         this.$confirm("是否已经结算, 结算完成请继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          axios.defaults.withCredentials = false;
          this.$axios
            .post(this.$api.queren, {
              id: this.tableData[index].id,
            })
            .then((res) => {
              if (res.code == 1) {
                 this.$message({
                    message: res.msg,
                    type: "success",
                  });
                 location.reload(); //刷新页面
                //判断是否结算成功
              }
            });
        })
        .catch(() => {
          // this.zl = 2;
          // this.but = 2;
          this.$message({
            type: "info",
            message: "已取消",
          });
        });

    },
    jz(row, index) {
      console.log(row);
      //  alert(index);
      this.$axios
        .post(this.$api.yuyueka, {
          id: this.tableData[index].id,
        })
        .then((res) => {
          this.options = res.data; // 表格数据
        });
      this.visibles = true;
      this.gridData = this.tableData[index].view;
      this.yingshou = this.tableData[index].total_price;
      this.xianjinzk = this.tableData[index].total_zkxj;
      this.xianjinyh = this.tableData[index].total_xj;
      // if(this.xianjinzk==0&&this.xianjinyh==0){
      //   this.but=1
      //   this.buput=0
      // }
    },
    changeRadio(val) {
      this.fangshiid = val;
      if (this.fangshiid == 1) {
        this.buput = 1; //收款输入框显示
      } else {
        this.buput = 2;
      }
      // alert(val);
    },
    changeColor() {
      if (this.smxianjin == "扫码") {
        this.smxianjin = "";
      } else {
        // this.smxianjin = this.smxianjin;
      }
    },
    searchEnterFunpay: function (e) {
      var keyCode = window.event ? e.keyCode : e.which;
      if (keyCode == 13) {
        this.$axios
          .post(this.$api.jiesuan, {
            card_id: this.cardid, //卡片id
            // user_id: this.memlist.user_id, //用户id
            goods_data: this.gridData, //结算商品
            auth_code: this.smxianjin, //扫码
            xj_collect_way: 1,
          })
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              //判断是否结算成功
              this.$message({
                message: res.msg,
                type: "success",
              });
              if (res.data.check_pay == true) {
                this.order_id = res.data.order_id;
                Loading.service({
                  fullscreen: true,
                  text: "正在查询支付结果...",
                  background: "rgba(0, 0, 0, 0.7)",
                });
                let timer = setInterval(() => {
                  this.checkpay(timer);
                }, 2000);
              }
            } else {
              this.smxianjin = "";
              console.log("sm");
              // this.$message({
              //   message: res.msg,
              //   type: "warning",
              // });
            }
          });
      }
    },
    checkpay(timer) {
      console.log(this.order_id);
      setTimeout(() => {
        this.$axios
          .post(
            this.$api.chapay,
            {
              order_id: this.order_id,
            },
            false
          )
          .then((res) => {
            if (res.code == 1) {
              if (res.data.pay_status == 1) {
                clearInterval(timer);
                //  loading.close();
                this.$alert("支付成功", "消息", {
                  confirmButtonText: "完成",
                  callback: (action) => {
                    this.visibles = false; //结算成功之后结算弹框隐藏
                    location.reload(); //刷新页面
                    console.log(action);
                  },
                });
              }
            } else {
              console.log("zf");
              clearInterval(timer);
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
      }, 0);
    },
    xianxia() {
      this.$axios
        .post(this.$api.jiesuan, {
          card_id: this.cardid, //卡片id
          // user_id: this.memlist.user_id, //用户id
          goods_data: this.gridData, //结算商品
          auth_code: this.smxianjin, //扫码
          xj_collect_way: 2,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            //判断是否结算成功
            this.$message({
              message: res.msg,
              type: "success",
            });
            if (res.data.check_pay == true) {
              this.order_id = res.data.order_id;
              Loading.service({
                fullscreen: true,
                text: "正在查询支付结果...",
                background: "rgba(0, 0, 0, 0.7)",
              });
              let timer = setInterval(() => {
                this.checkpay(timer);
              }, 2000);
            } else if (res.data.check_pay == false) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.visibles = false; //结算成功之后结算弹框隐藏
              location.reload(); //刷新页面
            }
          } else {
            this.smxianjin = "";
            console.log("sm");
            // this.$message({
            //   message: res.msg,
            //   type: "warning",
            // });
          }
        });
    },
    jiesuan() {
      //最终商品结算
      this.$confirm("请您确定结算内容, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          axios.defaults.withCredentials = false;
          this.$axios
            .post(this.$api.jiesuan, {
              card_id: this.cardid, //卡片id
              // user_id: this.memlist.user_id, //用户id
              goods_data: this.gridData, //结算商品
              auth_code: this.smxianjin, //扫码
            })
            .then((res) => {
              // if (res.code == 1) {
              if (res.code == 1) {
                if (res.data.check_pay == false) {
                  this.$message({
                    message: res.msg,
                    type: "success",
                  });
                  this.visibles = false; //结算成功之后结算弹框隐藏
                  location.reload(); //刷新页面
                } else if (res.data.check_pay == true) {
                  this.order_id = res.data.order_id;
                  Loading.service({
                    fullscreen: true,
                    text: "正在查询支付结果...",
                    background: "rgba(0, 0, 0, 0.7)",
                  });
                  let timer = setInterval(() => {
                    this.checkpay(timer);
                  }, 2000);
                }
                //判断是否结算成功
              } else {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              }
            });
        })
        .catch(() => {
          // this.zl = 2;
          // this.but = 2;
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    nopay() {
      //取消结算
      this.$message({
        message: "取消结算",
        type: "warning",
      });
      this.visibles = false;
      location.reload(); //刷新页面
      this.zl = 3;
      this.but = 3;
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
.xinxi {
  display: inline-block;
  width: 230px;
}
#box {
  width: 60%;
  vertical-align: middle;
  display: inline-block;
  margin-right: 5%;
  background: #50a3e5;
  color: #fff;
  white-space: nowrap;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.marquee-box {
  overflow: auto;
}
#pWidth {
  width: 60%;
  height: 50px;
  padding: 0;
  margin: 0;
  line-height: 50px;
  display: block;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  font-family: 微软雅黑;
  font-size: 14px;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.spbiao {
  width: 70%;
  left: 12%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

.head {
  text-align: right;
  background: #50a3e5;
  color: white;
  padding: 10px;
  margin: 15px;
}

.touimg {
  width: 45px;
  height: 45px;
  vertical-align: middle;
  border-radius: 50%;
}

.spname {
  font-size: 18px;
  display: inline-block;
  margin-left: 15px;
}
h5 {
  display: inline-block;
  width: 30%;
}
.notice {
  width: 65%;
  height: 50px;
  top: 25px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}
.notice /deep/ .el-card__body {
  padding: 0;
}
/*使文字和公告图片在一行*/
.notice_item {
  height: 50px;
  line-height: 50px;
  background: #50a3e5;
  color: white;
  white-space: nowrap;
  font-size: 14px;
  /* background-color: #fff; */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.notice_item img {
  width: 40px;
  /*height: 16px;*/
  margin: 0 44px 0 32px;
}
.el-card {
  border: none;
}
</style>
