<template>
  <div class="home">
    <router-view></router-view>
    <div class="head">
      <span style="float: left; line-height: 55px">
        <i class="el-icon-arrow-left" @click="$router.back(-1)"></i>商品零售</span>
      <div id="box" ref="box">
        <div class="marquee-box" ref="marquee" @mouseover="menter" @mouseleave="mleave">
          <p ref="cmdlist" id="pWidth" @click="detail(i)">
            <i class="el-icon-s-flag" style="color: red"></i>{{ gg }}
          </p>
        </div>
      </div>
      <img :src="imge" width="40" height="40" class="touimg" />
      <!-- <el-card class="notice">
        <el-carousel
          indicator-position="none"
          arrow="never"
          direction="horizontal"
          :interval="6000"
          :loop="true"
        >
          <el-carousel-item v-for="(i, ind) in newlist" :key="ind" class="notice_item">
            <i class="el-icon-s-flag" style="color: red"></i
            ><span @click="detail(i)" style="cursor: pointer"> {{ i.title }}</span>
          </el-carousel-item>
        </el-carousel>
      </el-card> -->
      <h2 class="spname">{{ shopname }}[{{ dptype }}]</h2>
    </div>
    <div>
      <el-table :data="tableData" border :summary-method="getSummaries" show-summary
        style="width: 100%; min-height: 260px">
        <el-table-column prop="goods_id" label="编码"> </el-table-column>
        <el-table-column prop="title" label="名称"> </el-table-column>
        <el-table-column prop="guige" label="单位"> </el-table-column>
        <el-table-column prop="price" label="易货额"> </el-table-column>
        <el-table-column prop="xj" label="易货现金"> </el-table-column>
        <el-table-column prop="give_integral" label="赠送积分"> </el-table-column>
        <el-table-column prop="zk_xj" label="折扣现金"> </el-table-column>
        <el-table-column prop="discount" label="打折（1-10）">
          <template slot-scope="scope">
            <el-input v-model="scope.row.discount" @blur="updat(scope.row, scope.$index)"
              @focus="up(scope.$index)"></el-input>
          </template>
        </el-table-column>
      
        <el-table-column prop="zhe_price" label="折后价"> </el-table-column>
        <el-table-column prop="goods_num" label="数量" type="number">
          <template slot-scope="scope">
            <el-input v-model="scope.row.goods_num" @blur="updat(scope.row, scope.$index)"
              @focus="up(scope.$index)"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="jine" label="总易货额"> </el-table-column>
        <el-table-column prop="jifen" label="赠送总积分"> </el-table-column>
        <el-table-column prop="xjjine" label="总易货现金"> </el-table-column>
        <el-table-column prop="zk_xjjine" label="总折扣现金"> </el-table-column>  <el-table-column prop="youhui" label="现金优惠价 ">
          <template slot-scope="scope">
            <el-input v-model="scope.row.youhui" @blur="updatyh(scope.row, scope.$index)"
              @change="up(scope.$index)" autocomplete="new-password" ></el-input>
          </template>
        </el-table-column> 
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="del(scope.$index)" icon="el-icon-delete"> 删除 </el-button><br />
            <!-- <el-button @click="xiu(scope.$index)" icon="el-icon-edit">
              修改
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin-top: 20px; text-align: left;width: auto;float: left;">
      上单应收：
      <el-input v-model="sdyingshou" placeholder="请输入内容" style="width: 200px" ref="sd" id="sd" disabled></el-input>  </div>
      <div style="margin-top: 20px; text-align: left;width: auto;float: left;">上单实收：
      <el-input v-model="sdshishou" placeholder="请输入内容" style="width: 200px" disabled></el-input></div> 
      <div style="margin-top: 20px; text-align: left;width: auto;float: left">上单优惠：
      <el-input v-model="youhui" placeholder="请输入内容" style="width: 200px" disabled></el-input></div>
  
    <div style="text-align: left; margin-top: 15px">
      <el-row> </el-row>
    </div>
    <div>
      <el-dialog title="" :visible.sync="visible">
        <el-table :data="gridData" @row-click="clickRow" ref="moviesTable" height="60vh">
          <el-table-column property="goods_id" label="编码"></el-table-column>
          <el-table-column property="title" label="名称"></el-table-column>
          <el-table-column property="guige" label="单位"></el-table-column>
          <!-- <el-table-column width="150" property="price" label="原价"></el-table-column> -->
          <el-table-column property="num" label="库存"></el-table-column>
          <el-table-column property="price" label="易货额"></el-table-column>
          <el-table-column property="xj" label="易货现金"></el-table-column>
          <el-table-column property="zk_xj" label="折扣现金"></el-table-column>
          <el-table-column property="give_integral" label="积分"></el-table-column>
   

          <el-table-column label="图片" width="150" height="150">
            <!-- class="demo-image__preview" -->
            <template slot-scope="scope" >
              <el-image style="width: 100px; height: 100px" :src="scope.row.photo"
                :preview-src-list="scope.row.photo" @error="defImg()">
              </el-image>
            </template>
          </el-table-column>

        </el-table>
      </el-dialog>
    </div>
    <el-dialog title=" " :visible.sync="visibles">
      <div>
        <div style="margin-top: 20px; text-align: left">
   <div class="xinxi">
              卡号：
              <div v-for="(item, index) in choseCard" :key="index">
                {{ item.cardnum }}
              </div>
            </div>

          <p>
            <span class="xinxi">卡号：{{ this.memlist.cardnum }}</span>
         
            
            <span class="xinxi">电话：{{ this.memlist.mobile
            }}</span>
          </p>
          <p>
            <span class="xinxi">姓名：{{ this.memlist.nickname }}</span><span class="xinxi">可用余额：{{ this.memlist.money
            }}</span>
          </p>
          <p v-show="but == 2">
            现金支付方式：
            <el-radio v-model="radio" label="1" @change="changeRadio($event)">线上支付</el-radio>
            <el-radio v-model="radio" label="2" @change="changeRadio($event)">线下收取</el-radio>
            <!-- <span class="xinxi">票据： 1张</span> 支付方式：
            <el-select v-model="fangshi" placeholder="储值卡" @change="zt">
              <el-option
                v-for="(itema, indea) in options"
                :key="indea"
                :name="itema.value"
                :label="itema.label"
                :value="itema.value"
              >
              </el-option>
            </el-select> -->
          </p>
          易货额度：
          <el-input v-model="yingshou" placeholder="请输入内容" style="width: 150px"
            @keyup.enter.native="searchEnterFunyingshou"  >{{ yingshou }}</el-input>
          应收现金：
          <el-input v-model="zhifuxj" placeholder="请输入内容" style="width: 150px" @keyup.enter.native="searchEnterFunzhifu"
            disabled>{{ zhifuxj }}</el-input><br />
          <div v-show="buput == 1" style="margin: 3% 0">
            微信扫码：
            <el-input type="text" v-model="smxianjin" placeholder="点击后扫微信付款码支付" style="width: 150px" @focus="changeColor"
              @keyup.enter.native="searchEnterFunpay" ref="seach_input" clearable v-focus></el-input>
            <br /><span style="color: red">*点击输入框后扫描微信付款码支付*</span>
          </div>
          <el-button type="success" @click="xianxia" v-show="buput == 2" style="margin: 5%">线下结算</el-button>
        </div>
        <el-button type="success" @click="jiesuan" v-show="but == 1" style="margin: 5%">结算</el-button>
        <el-button :plain="true" @click="nopay" v-show="but == 1" style="margin: 5%">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="" :visible.sync="visiblez">
      <div>
        卡号：：
        <el-input v-model="huiyuan" placeholder="请输入内容" style="width: 150px" disabled></el-input>
        密码：
        <el-input v-model="mima" show-password placeholder="请输入内容" style="width: 150px"
          @keyup.enter.native="searchEnterFunmima" autocomplete="new-password"></el-input>
        <el-button type="warning" @click="mimabut" style="margin-left: 20px">确定</el-button>
        <br />
        <div v-show="zl == 1">
          <p>会员资料</p>
          <div style="margin-top: 20px; text-align: left">
            <p>
              <span class="xinxi">卡号：{{ this.memlist.card_num }}</span><span class="xinxi">身份证号：</span><span
                class="xinxi">余额：{{ this.memlist.money }}</span>
            </p>
            <p>
              <span class="xinxi">姓名：{{ this.memlist.nickname }}</span><span class="xinxi">电话：{{ this.memlist.mobile
              }}</span>
            </p>
            <p>
              <span class="xinxi">性别：</span><span class="xinxi">手机：{{ this.memlist.mobile }}</span>
            </p>
            <p><span class="xinxi">开户：</span></p>
          </div>
          <el-button type="success" @click="tijiao">确定</el-button>
          <el-button type="success" @click="quxiao">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <div style="margin-top: 20px; text-align: left">
    <div style="float: left;">
      商品：
      <el-input v-model="bianma" placeholder="请输入品名(可关键字)或编码" style="width: 230px"
        @keyup.enter.native="searchEnterFunbianma"></el-input>
      <el-button type="primary" icon="el-icon-sort" @click="spbianma"></el-button>
     </div>
     <div style="float: left;">
      卡号|手机号码：
      <el-input v-model="phonenum" placeholder="请输入内容" style="width: 200px"
        @keyup.enter.native="searchEnterFunhuiyuan"></el-input>
      <el-button type="primary" icon="el-icon-search" @click="ok"></el-button> 
    </div>

<div style="float: left;width:100%">
  订单备注：
      <el-input
        v-model="gonghao"
        placeholder="请输入内容"
        style="width: 390px"
      ></el-input>
</div>

<div style="float: left;">
  <a :href="'https://www.zhilongyihuo.com/userorders.html?shopid=' + shop_id"><el-button
          type="primary">消费打印</el-button></a>
      <br /><br />
      <el-button type="info" @click="gua">挂单</el-button>
      <el-button type="info" @click="qu">取单</el-button>
      <el-button type="danger" @click="alldel">整单取消</el-button>
      <el-button type="warning" @click="jz">结账</el-button>
</div>
      <div style="font-size: 15px; color: #333">
          <div v-for="(item, index) in choseCard" :key="index">
            <!-- <div v-if="index === chosenCardIndex" style="display: flex; align-items: center"> -->
            <div style="display: flex; align-items: center">
              卡号:
              {{ item.cardnum }} 余额: {{ item.available_money }}
              <div style="
                  margin: 0 6px;
                  color: #999;
                  font-size: 16px;
                  cursor: pointer;
                " @click="delCard(item)">
                x
              </div>
            </div>
          </div>
        </div>

  
      <div>
        <el-dialog title="" :visible.sync="visibleka">
          <el-table :data="kaData" @row-click="clickRowka" ref="moviesTable" height="520">
            <el-table-column property="card_id" label="编码"></el-table-column>
            <el-table-column property="cardnum" label="卡号"></el-table-column>
            <el-table-column label="卡号类型" prop="card_type" :formatter="stateFormat"></el-table-column>
            <el-table-column property="nickname" label="姓名"></el-table-column>
            <el-table-column property="money" label="原始额度"></el-table-column>
            <el-table-column property="dongjie" label="冻结额度"></el-table-column>
            <el-table-column property="available_money" label="可用额度"></el-table-column>
            <el-table-column property="total_consume" label="消费额"></el-table-column>
            <el-table-column property="shop_name" label="所属网点"></el-table-column>
            <el-table-column label="异店消费总额">
              <template slot-scope="scope">
                <el-button type="text" size="medium" style="color:red">{{ scope.row.total_another_pay }}</el-button>
              </template>
            </el-table-column>
            <el-table-column label="异店消费单次限额">
              <template slot-scope="scope">
                <el-button type="text" size="medium" style="color:red">{{ scope.row.one_another_pay }}</el-button>
              </template>
            </el-table-column>
            <el-table-column label="单日总额">
              <template slot-scope="scope">
                <el-button type="text" size="medium" style="color:#4caf50">{{ scope.row.day_another_pay }}</el-button>
              </template>
            </el-table-column>
            <el-table-column label="单日剩余">
              <template slot-scope="scope">
                <el-button type="text" size="medium" style="color:#4caf50">{{ scope.row.day_another_lost }}</el-button>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.row, scope.$index)" type="text" size="medium">消费记录</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-dialog>
      </div>
      <div>
        <el-dialog title="" :visible.sync="visiblexf" width="90%">
          <el-table :data="xfData" border style="width: 100%">
            <el-table-column fixed prop="id" label="ID"> </el-table-column>
            <el-table-column prop="nickname" label="用户名"> </el-table-column>
            <el-table-column prop="account" label="电话"> </el-table-column>
            <el-table-column prop="cardnum" label="卡号"> </el-table-column>
            <el-table-column prop="title" label="商品名"> </el-table-column>
            <el-table-column prop="shop_name" label="网点"> </el-table-column>
            <el-table-column property="photo" label="商品图">
              <template slot-scope="scope">
                <img :src="scope.row.photo" @error="defImg()" width="40" height="40" />
              </template>
            </el-table-column>
            <el-table-column prop="price" label="价格"> </el-table-column>
            <el-table-column prop="give_integral" label="积分"> </el-table-column>
            <el-table-column prop="discount" label="折扣"> </el-table-column>
            <el-table-column prop="discount_price" label="折后价"> </el-table-column>
            <el-table-column prop="num" label="数量"> </el-table-column>
            <el-table-column prop="total_price" label="总价"> </el-table-column>
            <el-table-column prop="refund_num" label="退货数"> </el-table-column>
            <el-table-column prop="refund_money" label="退货金额"> </el-table-column>
            <el-table-column prop="xj" label="现金"> </el-table-column>
            <el-table-column prop="total_xj" label="总现金"> </el-table-column>
            <el-table-column prop="total_give_integral" label="总积分"> </el-table-column>
            <el-table-column property="refund_integral" label="退货积分"></el-table-column>
            <el-table-column fixed="right" prop="create_time" label="创建时间">
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination @size-change="handleSizeChanges" @current-change="handleCurrentChanges"
              :current-page="currentPage" :page-sizes="[100, 300, 500]" :page-size="pagesizes"
              layout="total, sizes, prev, pager, next, jumper" :total="totals">
            </el-pagination>
          </div>
        </el-dialog>
      </div>
      <div>
        <el-dialog title="" :visible.sync="visibleg" width="90%">
          <div v-for="(item, index) in qudanlist" :key="index">
            <div class="dank" @click="shop(item, index)">
              {{ item.store_no }}
            </div>
            <ul>
              <li class="xi">
                <span>编码</span><span>名称</span> <span>库存</span>
                <span>单位</span><span>易货额</span><span>易货现金</span><span>折扣现金</span><span>积分</span>
                <span>折扣</span><span>数量</span>
              </li>
              <li class="xi" v-for="(items, indexs) in item.item" :key="indexs" :data-index="indexs">
                <span>{{ items.goods_id }} </span>
                <span>{{ items.title }} </span>
                <span>{{ items.num }} </span> <span>{{ items.guige }} </span><span>{{ items.price }} </span>
                <span>{{ items.xj }} </span>
                <span>{{ items.zk_xj }} </span>
                <span>{{ items.give_integral }} </span>
                <span>{{ items.discount }} </span>
                <span>{{ items.goods_num }} </span>
              </li>
            </ul>
          </div>
        </el-dialog>
      </div>
    </div>
    <!-- <div style="text-align: left; margin-top: 15px">
      <el-row> </el-row>
    </div> -->
  </div>
</template>
<script src="https://cdn.staticfile.org/jquery/1.10.2/jquery.min.js"></script>
<script>
import axios from "axios";
import { Message, MessageBox, Loading } from "element-ui";
export default {
  name: "retail",
  data() {
    return {
      zl: 2, //密码输入下面的会员资料
      but: 3, //结算按钮
      radio: "2",
      buput: 2,
      pxj: 3, //判断卡类型显示应收现金
      zhifuxj: 0,
      memlist: [], //会员信息数组
      key: "",
      yingshou: "",
      cardid: "",
      sdyingshou: "",
      choseCard:[], 
      sdzhifu: "",
      sdshishou: "",
      zhifu: "", //现金
      zhifuz: "", //折扣现金
      foc: "", //当前在某个框
      smxianjin: "", //扫码
      uid: "",
      zhaoling: "",
      youhui: "0",
      visible: false, //商品弹框
      visibleka: false, //卡号弹框
      visibles: false, //结账弹框
      visibleg: false, //取单弹框
      visiblez: false, //输入密码弹框
      visiblexf: false, //消费表
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      totals: 0, // 总条目数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 100, // 每页显示条数
      pagenums: 1, // 当前页码
      pagesizes: 100, // 每页显示条数
      kahao: "",
      mima: "",
      input: "",
      fangshi: "", //结算方式
      fangshiid: 1, //结算方式
      bianma: "",
      phonenum: "", //手机号搜索
      huiyuan: "",
      shuliang: "",
      gonghao: "",
      ci: "",
      val: "",
      qudanlist: [],
      imge: "",
      shopname: "",
      order_id: "", //扫码传得id
      options: [
        {
          value: "1",
          label: "储值卡",
        },
        {
          value: "1",
          label: "线上支付",
        },
        {
          value: "2",
          label: "线下支付",
        },
      ],
      value: "",
      leixing: "", //卡类型
      gridData: [], //商品详情表
      tableData: [], //页面商品表
      kaData: [], //卡号列表
      xfData: [], //消费表
      xj: 0,
      dptype: "", //店铺类型
      type_id: "",
      zhe_price: 0, //折后价
      yihuoe: 0, //总易货额
      jifen: 0, //总积分
      xjjine: 0, //现金总金额
      zk_xjjine: 0, //折扣现金总金额
      newlist: [],
      timer: "", //计时器
      pwidth: 0, //公告文本的宽度
      value: 0,
      zyhe:0,
      gg: "",
      i: "",
      titleStr: "",
      // windowWidth:'', // box宽度
      windowWidth: document.documentElement.clientWidth / 2.5,
      shop_id: localStorage.getItem("shop_id"),
      cardnum:"",
      ids:""
    };
  },
  mounted() {
    let element = this.$refs.cmdlist;
    this.pwidth = document.defaultView.getComputedStyle(element, "").width.split("px");
   // this.timer = setInterval(this.clickCommend, 20);
    axios.defaults.withCredentials = false;
    this.shopname = localStorage.getItem("shopname");
    this.imge = localStorage.getItem("imge");
    this.type_id = localStorage.getItem("type_id");
    if (this.type_id == 3) {
      this.dptype = "易货店";
    } else if (this.type_id == 5) {
      this.dptype = "折扣仓";
    }
    this.$axios.post(this.$api.new, {}).then((res) => {
      if (res.code == 1) {
        this.newlist = res.data;
        this.gg = this.newlist[0].title;
        this.i = 0;
      }
    });
    this.$axios.get(this.$api.lastOrder, {}).then((res) => {
      //上单应收
      if (res.code == 1) {
        this.sdyingshou = res.data.total_price;
        this.sdshishou = res.data.total_price;
      }
    });
  },
  watch: {
    value(newValue) {
      let allWidth = parseInt(this.windowWidth) + parseInt(this.pwidth[0]);
      // console.log(oldValue);
      if (newValue <= -allWidth) {
        this.$refs.cmdlist.style.marginLeft = this.windowWidth + "px";
        this.value = 0;
      }
    },
  },
  methods: {
    delCard(e) {
      this.choseCard.splice(e, 1);
      // const index = this.choseCard.findIndex(
      //   (card) => card.cardnum === item.cardnum
      // );
      console.log(JSON.stringify(this.choseCard))
      // if (index !== -1) {
      //   this.choseCard.splice(index, 1);
      //   if (index === this.chosenCardIndex) {
      //     this.chosenCardIndex = -1; // 隐藏卡号信息
      //   }
      // }
    },
    clickCommend(e) {
      // console.log(e);
      let _this = this;
      this.$nextTick(() => {
        this.value -= 1;
        this.$refs.cmdlist.style.marginLeft = _this.windowWidth + this.value + "px";
      });
    },
    menter() {
      clearInterval(this.timer);
    },
    mleave() {
    //  this.timer = setInterval(this.clickCommend, 20);
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    changeRadio(val) {
      this.fangshiid = val;
      console.log(val);
      if (this.zhifuxj > 0) {
        this.but = 2; //按钮隐藏
        if (this.fangshiid == 1) {
          this.$nextTick(() => {
            this.$refs.seach_input.focus();
          });
          this.buput = 1; //收款输入框显示

          //  this.$refs.seach_input.focus();
          //       setTimeout(function() {
          //   this.$refs['seach_input'].focus()
          // }, 2000)
        } else {
          this.buput = 2;
        }
      } else {
        this.but = 1; //按钮显示
        this.buput = 3; //收款输入框隐藏
      }
    },
    defImg() {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
    // zt(data) {
    //   this.fangshiid = data;
    //   console.log(this.fangshiid);
    //   if (this.zhifuxj > 0) {
    //     this.but = 2; //按钮隐藏
    //     if (this.fangshiid == 1) {
    //       this.buput = 1; //收款输入框显示
    //     } else {
    //       this.buput = 2;
    //     }
    //   } else {
    //     this.but = 1; //按钮显示
    //     this.buput = 3; //收款输入框隐藏
    //   }
    // },
    detail(i) {
      this.$router.push({
        name: "proinfo",
        params: { details: this.newlist[i].details, 
          title: this.newlist[i].title },
      });
    },
    stateFormat(row) {
      if (row.card_type === 3) {
        return "易货卡";
      } else if (row.card_type === 5) {
        return "折扣卡";
      }
    },
    handleClick(row, index) {
      console.log(row);
      console.log(index);
      this.cardnum=row.cardnum
      this.ids=row.card_id
      this.$axios
        .post(this.$api.xiaofei, {
          cardnum: this.cardnum, // 卡号
          title: this.cardinput, // 商品名
          account: this.input, //用户账号
          nickname: this.nameinput, //用户姓名
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
          start_time: this.start,
          end_time: this.end,
          id: this.ids,
          // id: this.$route.query.id,
        })
        .then((res) => {
          this.xfData = res.data.list; // 表格数据
          this.totals = res.data.count; // 总条目数
          this.visiblexf = true;
        });
    },
    getUserinfo(){
      this.$axios
        .post(this.$api.xiaofei, {
          cardnum: this.cardnum, // 卡号
          title: this.cardinput, // 商品名
          account: this.input, //用户账号
          nickname: this.nameinput, //用户姓名
          page: this.pagenums, // 当前页码
          page_size: this.pagesizes, // 每页显示条数
          start_time: this.start,
          end_time: this.end,
          id: this.ids,
          // id: this.$route.query.id,
        })
        .then((res) => {
          this.xfData = res.data.list; // 表格数据
          this.totals = res.data.count; // 总条目数
          this.visiblexf = true;
        });
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
     handleSizeChanges(val) {
      //改变时
      this.pagesizes = val;
      this.getUserinfo();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChanges(val) {
      //条目改变时
      this.pagenums = val;
      this.getUserinfo();
      console.log(`当前页: ${val}`);
    },
    del(strs) {
      //删除某行
      console.log(strs);
      this.tableData.splice(strs, 1);
    },
    alldel() {
      //删除整个表单
      this.tableData = [];
    },
    gua() {
      //挂单
      console.log(this.tableData);
      for (var i = 0; i < this.tableData.length; i++) {
        console.log(this.tableData[i]);
      }
      this.$axios
        .post(this.$api.gua, {
          user_id: this.uid,
          goods_data: this.tableData,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.tableData = [];
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        });
    },
    qu() {
      //取单
      axios.defaults.withCredentials = false;
      this.$axios.post(this.$api.qu, {}).then((res) => {
        if (res.code == 1) {
          for (var i = 0; i < res.data.length; i++) {}
          this.qudanlist = res.data;
          if (this.qudanlist.length == 0) {
            this.$message({
              message: "暂无内容",
              type: "warning",
            });
            this.visibleg = false;
          } else {
            this.visibleg = true;
          }
        }
      });
    },
    xiu(stri) {
      //修改
      console.log(stri);
      console.log(this.tableData[stri].goods_num);
      this.ci = stri;
    },
    mimainfo() {
      //验证密码
      this.kahao = this.memlist.card_num;
      this.visibles = false;
      this.visiblez = true;
    },
    mimabut() {
      console.log("现金",this.zhifuz)
      console.log("现金=",this.zhifu)
      console.log("typeid=",this.type_id)  
       console.log("leixing=",this.leixing) 
    
       if(this.zyhe==0){
        this.cardid=75739;
         this.mima="111111"
       }
      this.$axios
        .post(this.$api.kainfo, {
          card_id: this.cardid,
          zyhe:this.zyhe,
          password: this.mima,
        })
        .then((res) => {
          if (res.code == 1) {
            this.visiblez = false;
            this.visibles = true;
            this.smxianjin = "扫码";
            if (this.type_id == 3 && this.leixing == 5) {
              this.zhifuxj = this.zhifuz;
            } else {
              // this.zhifuxj = this.zhifu;
               this.zhifuxj = this.zhifuz;
            }
            this.zhifuxj = this.zhifu;
            console.log("现金：",this.zhifuxj);
            if (this.zhifuxj > 0) {
              this.but = 2; //按钮隐藏
              if ((this.fangshiid = 1)) {
                this.buput = 2; //收款输入框显示
              } else {
                this.buput = 2;
              }
            } else {
              this.but = 1; //按钮显示
              this.buput = 3; //收款输入框隐藏
            }
            this.$message({
              message: "密码正确",
              type: "success",
            });
            this.memlist = res.data;
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        });
    },
    changeColor() {
      if (this.smxianjin == "扫码") {
        this.smxianjin = "";
      } else {
        this.smxianjin = this.smxianjin;
      }
    },
    find(goodsdata) {
      if (goodsdata.length == 0) {
        this.$message({
          message: "未查询到该商品",
          type: "warning",
        });
      } else if (goodsdata.length == 1) {
        let index = this.tableData.findIndex(
          (item) => item.goods_id == goodsdata[0].goods_id
        );
        console.log(index);
        if (index != -1) {
          this.bianma = "";
          this.tableData[index].goods_num++;
          this.updat(this.tableData[index], index);
        } else {
          let thas;
          thas = this;
          var rows = goodsdata[0];
          console.log(rows);
          if (!rows.hasOwnProperty("discount")) {
            //判断对象中是否含有
            rows.discount = "";
          }
          thas.zhe_price = rows.discount
            ? ((rows.price * rows.discount) / 10).toFixed(2)
            : rows.price;
          console.log(11111, thas.zhe_price);
          if (!rows.hasOwnProperty("goods_num")) {
            rows.goods_num = 1;
          }
          thas.yihuoe = (thas.zhe_price * rows.goods_num).toFixed(2);
          thas.jifen = (rows.give_integral * rows.goods_num).toFixed(2);
          thas.xjjine = (rows.xj * rows.goods_num).toFixed(2);
          thas.zk_xjjine = (rows.zk_xj * rows.goods_num).toFixed(2);
          console.log(rows);
          // return false
          // this.$refs.moviesTable.toggleRowSelection(row);
          console.log(thas.zhe_price);
          var str =
            "{ goods_id: rows.goods_id, guige: rows.guige,price: rows.price,xj:rows.xj,give_integral: rows.give_integral,zk_xj:rows.zk_xj,title: rows.title,goods_num:rows.goods_num,price:rows.price,zhe_price:thas.zhe_price,discount:rows.discount,jine:thas.yihuoe,jifen:thas.jifen,xjjine:thas.xjjine,zk_xjjine:thas.zk_xjjine}";
          // console.log("(" + str + ")");
          console.log(eval("(" + str + ")"));
          this.tableData.push(eval("(" + str + ")"));
          console.log(this.tableData);
          console.log(rows.price);
          this.visible = false;
          this.visibleg = false;
          this.bianma = "";
        }
      } else {
        this.visible = true;
      }
    },
    searchEnterFunbianma: function (e) {
      //查询商品
      var keyCode = window.event ? e.keyCode : e.which;
      // let val = e.target.value;
      if (keyCode == 13) {
        axios.defaults.withCredentials = false;
        this.$axios
          .post(this.$api.bianma, {
            title: this.bianma,
          })
          .then((res) => {
            this.gridData = res.data;
            this.find(this.gridData);
          });
      }
    },
    spbianma() {
      //查询商品 
      axios.defaults.withCredentials = false;
      this.$axios
        .post(this.$api.bianma, {
          title: this.bianma,
        })
        .then((res) => {
          this.gridData = res.data;
          this.find(this.gridData);
        });
    },
    searchEnterFunhuiyuan: function (e) {
      //会员查询
      var keyCode = window.event ? e.keyCode : e.which;
      if (keyCode == 13) {
        this.visibleka = true;
        console.log(this.visibleka);
        //搜索会员信息
        axios.defaults.withCredentials = false;
        this.$axios
          .post(this.$api.kahao, {
            number: this.phonenum,
          })
          .then((res) => {
            console.log(res.data);
            this.visibleka = true;
            if (res.code == 1) {
              // this.huiyuan = res.data.cardnum + "--" + res.data.nickname;
              this.kaData = res.data;
              this.memlist = res.data;
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
      }
    },

    tijiao() {
      //验证密码确定 
      this.visibles = true;
      this.visiblez = false;
      this.zhaoling = 0;
      this.youhui = 0;
    },
    quxiao() {
      //验证密码取消
      this.visibles = true;
      this.visiblez = false;
      this.zl = 3;
      this.but = 3;
      this.fangshi = null;
    },
    jiesuan() {
      //最终商品结算
      this.$confirm("请您确定结算内容, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          axios.defaults.withCredentials = false;
          this.$axios
            .post(this.$api.jiesuan, {
              card_id: this.memlist.card_id, //卡片id
              choseCard:JSON.stringify(this.choseCard),
              // user_id: this.memlist.user_id, //用户id
              goods_data: this.tableData, //结算商品
              auth_code: this.smxianjin, //扫码
              beizhu:this.gonghao
            })
            .then((res) => {
              // if (res.code == 1) {
              if (res.code == 1) {
                if (res.data.check_pay == false) {
                  this.$message({
                    message: res.msg,
                    type: "success",
                  });
                  this.visibles = false; //结算成功之后结算弹框隐藏
                  location.reload(); //刷新页面
                } else if (res.data.check_pay == true) {
                  this.order_id = res.data.order_id;
                  Loading.service({
                    fullscreen: true,
                    text: "正在查询支付结果...",
                    background: "rgba(0, 0, 0, 0.7)",
                  });
                  let timer = setInterval(() => {
                    this.checkpay(timer);
                  }, 2000);
                }
                //判断是否结算成功
              } else {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              }
            });
        })
        .catch(() => {
          // this.zl = 2;
          // this.but = 2;
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    nopay() {
      //取消结算
      this.$message({
        message: "取消结算",
        type: "warning",
      });
      this.visibles = false;
      this.zl = 3;
      this.but = 3;
    },
    xianxia() {
      console.log("xianjingh:",this.gonghao)
      this.$axios
        .post(this.$api.jiesuan, {
          card_id: this.memlist.card_id, //卡片id
          // user_id: this.memlist.user_id, //用户id
          goods_data: this.tableData, //结算商品
          auth_code: this.smxianjin, //扫码
          beizhu:this.gonghao,
          xj_collect_way: 2,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            //判断是否结算成功
            this.$message({
              message: res.msg,
              type: "success",
            });
            if (res.data.check_pay == true) {
              this.order_id = res.data.order_id;
              Loading.service({
                fullscreen: true,
                text: "正在查询支付结果...",
                background: "rgba(0, 0, 0, 0.7)",
              });
              let timer = setInterval(() => {
                this.checkpay(timer);
              }, 2000);
            } else if (res.data.check_pay == false) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.visibles = false; //结算成功之后结算弹框隐藏
              location.reload(); //刷新页面
            }
          } else {
            this.smxianjin = "";
            console.log("sm");
            // this.$message({
            //   message: res.msg,
            //   type: "warning",
            // });
          }
        });
    },
    searchEnterFunpay: function (e) {
      var keyCode = window.event ? e.keyCode : e.which;
      if (keyCode == 13) {
        this.$axios
          .post(this.$api.jiesuan, {
            card_id: this.memlist.card_id, //卡片id
            // user_id: this.memlist.user_id, //用户id
            goods_data: this.tableData, //结算商品
            auth_code: this.smxianjin, //扫码
            xj_collect_way: 1,
          })
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              //判断是否结算成功
              this.$message({
                message: res.msg,
                type: "success",
              });
              if (res.data.check_pay == true) {
                this.order_id = res.data.order_id;
                Loading.service({
                  fullscreen: true,
                  text: "正在查询支付结果...",
                  background: "rgba(0, 0, 0, 0.7)",
                });
                let timer = setInterval(() => {
                  this.checkpay(timer);
                }, 2000);
              }
            } else {
              this.smxianjin = "";
              console.log("sm");
              // this.$message({
              //   message: res.msg,
              //   type: "warning",
              // });
            }
          });
      }
    },
    checkpay(timer) {
      console.log(this.order_id);
      setTimeout(() => {
        this.$axios
          .post(
            this.$api.chapay,
            {
              order_id: this.order_id,
            },
            false
          )
          .then((res) => {
            if (res.code == 1) {
              if (res.data.pay_status == 1) {
                clearInterval(timer);
                //  loading.close();
                this.$alert("支付成功", "消息", {
                  confirmButtonText: "完成",
                  callback: (action) => {
                    this.visibles = false; //结算成功之后结算弹框隐藏
                    location.reload(); //刷新页面
                  },
                });
              }
            } else {
              console.log("zf");
              clearInterval(timer);
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
      }, 0);
    },
    ok() {
      this.visibleka = true;
      console.log(this.visibleka);
      //搜索会员信息
      axios.defaults.withCredentials = false;
      this.$axios
        .post(this.$api.kahao, {
          number: this.phonenum,
        })
        .then((res) => {
          console.log(res.data);
          this.visibleka = true;
          if (res.code == 1) {
            // this.huiyuan = res.data.cardnum + "--" + res.data.nickname;
            this.kaData = res.data;
            this.memlist = res.data;
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        });
    },

    shop(item, index) {
      //取某单
      for (var i = 0; i < item.item.length; i++) {
        this.clickRow(item.item[i]);
      }
      axios.defaults.withCredentials = false;
      this.$axios
        .post(this.$api.qushop, {
          id: item.id,
        })
        .then((res) => {
          // this.tableData = item.item;
          // this.visibleg = false;
        });
    },
    //点击商品详情显示
    clickRow(row) {
      console.log(row);
      //判断数组是选择商品还是取单
      if (!row.hasOwnProperty("discount")) {
        //判断对象中是否含有
        row.discount = "";
      }
      let index = this.tableData.findIndex((item) => item.goods_id == row.goods_id);
      console.log(index);
      if (index != -1) {
        this.bianma = "";
        this.visible = false;
        this.visibleg = false;
        // alert(row.goods_num);
        if (row.goods_num != undefined) {
          this.tableData[index].goods_num =
            this.tableData[index].goods_num + row.goods_num;
        } else {
          this.tableData[index].goods_num++;
        }

        this.updat(this.tableData[index], index);
      } else {
        this.zhe_price = row.discount
          ? ((row.price * row.discount) / 10).toFixed(2)
          : row.price;
        console.log(11111, this.zhe_price);
        if (!row.hasOwnProperty("goods_num")) {
          row.goods_num = 1;
        }
        this.yihuoe = (this.zhe_price * row.goods_num).toFixed(2);
        this.jifen = (row.give_integral * row.goods_num).toFixed(2);
        this.xjjine = (row.xj * row.goods_num).toFixed(2);
        this.zk_xjjine = (row.zk_xj * row.goods_num).toFixed(2);
        console.log(row);
        // return false
        // this.$refs.moviesTable.toggleRowSelection(row);
        var str =
          "{ goods_id: row.goods_id, guige: row.guige,price: row.price,xj:row.xj,give_integral: row.give_integral,zk_xj:row.zk_xj,title: row.title,goods_num:row.goods_num,price:row.price,zhe_price:this.zhe_price,discount:row.discount,jine:this.yihuoe,jifen:this.jifen,xjjine:this.xjjine,zk_xjjine:this.zk_xjjine}";
        console.log(eval("(" + str + ")"));
        console.log(eval("(" + str + ")"));
        this.tableData.push(eval("(" + str + ")"));
        console.log(this.tableData);
        console.log(row.price);
        this.visible = false;
        this.visibleg = false;
        this.bianma = "";
      }
    },
    clickRowka(row) {
    //    this.choseCard.forEach((e) => {
    //     console.log(row.cardnum+"=="+e.cardnum)
    //  if(row.cardnum==e.cardnum){
    //   console.log("ttttttttttttttttttttttttttt")
    //   return false;
    //  }
    // });
    for(let i=0;i<this.choseCard.length;i++){
         if(row.cardnum==this.choseCard[i].cardnum){
      console.log("ttttttttttttttttttttttttttt")
      this.visibleka = false;
      return false;
     }
    }
      //点击商品详情显示
      console.log(row.card_type);
      this.leixing = row.card_type;
      this.$refs.moviesTable.toggleRowSelection(row);
      console.log(row.cardnum);
      this.choseCard.push(row); // 添加新卡号
      this.huiyuan = row.cardnum;
      this.visibleka = false;
      this.uid = row.uid;
      this.cardid = row.card_id;
      this.xj = row.xj;
      // this.visibleg = false;
    },

    up(stri) {
      //修改数量
      console.log(stri);
      // this.ci = stri;
      this.updat(this.tableData[stri], stri);
    },
    jz() {
      console.log(this.tableData.length);
      console.log(typeof this.cardid);
      console.log("jz:",this.gonghao)
      if (this.cardid !== "" && this.tableData.length > 0 && this.zyhe!=0) {
        this.visiblez = true;
        console.log(this.huiyuan);
      } else {
        if(this.zyhe==0){
         //this.visiblez = true; 
         this.mimabut()
//this.tijiao()
        }else{

            this.$message({
          message: "请确认您输入的内容",
          type: "warning",
        });
        }  
      }
    },
    updat(row, item) {
      //失去焦点修改数量
      console.log(row);
      console.log(item);
      console.log(row.discount);
      var x = parseInt(row.discount);
      if (row.discount != "") {
        if (isNaN(x) || x < 0 || x > 10) {
          this.$message({
            message: "折扣必须在0-10之间",
            type: "warning",
          });
          row.discount = "";
        }
      }
      var n = parseInt(row.goods_num);
      if (row.goods_num != "") {
        if (isNaN(n) || n < 1) {
          this.$message({
            message: "请输入正确的数量",
            type: "warning",
          });
          row.goods_num = 1;
        }
      } else {
        row.goods_num = 1;
      }
      if (row.discount == "") {
        this.tableData[item].zhe_price = this.tableData[item].price.toFixed(2);
      } else {
        this.tableData[item].zhe_price = (
          (this.tableData[item].price * this.tableData[item].discount) /
          10
        ).toFixed(2);
      }

      // if(row.discount>10)
      this.tableData[item].jine = (
        this.tableData[item].zhe_price * this.tableData[item].goods_num
      ).toFixed(2);
      var yjjj=this.tableData[item].youhui?this.tableData[item].youhui:this.tableData[item].xj;
      this.tableData[item].xjjine = (
        
        yjjj * this.tableData[item].goods_num
      ).toFixed(2);
      this.tableData[item].zk_xjjine = (
        this.tableData[item].zk_xj * this.tableData[item].goods_num
      ).toFixed(2);
      this.tableData[item].jifen = (
        this.tableData[item].give_integral * this.tableData[item].goods_num
      ).toFixed(2);
    },   
    updatyh(row, item) {
      //失去焦点修改数量
      console.log(row);
      console.log(item);
      console.log(row.discount);
      var x = row.youhui;
      if (row.youhui != "") {
        if ( x < 0) {
          this.$message({
            message: "必须大于0",
            type: "warning",
          });
          row.youhui = "";
        }
      }
      var n = parseInt(row.goods_num);
      if (row.goods_num != "") {
        if (isNaN(n) || n < 1) {
          this.$message({
            message: "请输入正确的数量",
            type: "warning",
          });
          row.goods_num = 1;
        }
      } else {
        row.goods_num = 1;
      }
      if (row.youhui == "") {
        this.tableData[item].zhe_price = this.tableData[item].xj.toFixed(2);
      } else {
        this.tableData[item].zhe_price = (
          x).toFixed(2);
      }

      // if(row.discount>10)
     
      this.tableData[item].xjjine = (
        x * this.tableData[item].goods_num
      ).toFixed(2);
      this.tableData[item].zk_xjjine = (
        this.tableData[item].zk_xj * this.tableData[item].goods_num
      ).toFixed(2);
     
    },
    getSummaries(param) {
      //表格计算总价
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总价";
          return;
        } else if (index === 10) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              console.log(prev + "==10="+curr)
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            this.yingshou = sums[index]; //易货额
            // this.zhifu = sums[index];
            // this.smxianjin = sums[index];
            console.log(sums[index]);
            sums[index] = sums[index].toFixed(2);
            this.zyhe=sums[index];
            sums[index] += " 元";
            
          } else {
            sums[index] = "N/A";
          }
       
        } else if (index === 12) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                console.log(prev + "=12=="+curr)
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            // this.yingshou = sums[index];
            // this.smxianjin = sums[index];
            console.log(sums[index]);
            sums[index] = sums[index].toFixed(2);
            this.zhifu = sums[index]; //现金
            sums[index] += " 元";
          } else {
            sums[index] = "N/A";
          }
        } else if (index === 13) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            // this.yingshou = sums[index];
            // this.smxianjin = sums[index];
            console.log(sums[index]);
            sums[index] = sums[index].toFixed(2);
            this.zhifuz = sums[index]; //折扣现金
            sums[index] += " 元";
          } else {
            sums[index] = "N/A";
          }
        } else if (index === 9) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " 个";
          } else {
            sums[index] = "N/A";
          }
        } else if (index === 11) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          }
         
        }  else {
          sums[index] = "-";
        }
      });
   
      console.log(sums);
      return sums;
    },
  },
};
</script>
<style scoped>
#box {
  width: 60%;
  vertical-align: middle;
  display: inline-block;
  margin-right: 5%;
  background: #50a3e5;
  color: #fff;
  white-space: nowrap;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.marquee-box {
  overflow: auto;
}

#pWidth {
  width: 60%;
  height: 50px;
  padding: 0;
  margin: 0;
  line-height: 50px;
  display: block;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  font-family: 微软雅黑;
  font-size: 14px;
}

::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.home {
  width: 94%;
  margin: 0 3%;
}

.head {
  text-align: right;
  background: #50a3e5;
  color: white;
  padding: 10px;
}

.touimg {
  width: 45px;
  height: 45px;
  vertical-align: middle;
  border-radius: 50%;
}

.spname {
  font-size: 18px;
  display: inline-block;
  margin-left: 15px;
}

.xinxi {
  display: inline-block;
  width: 230px;
}

.xinxibiao {
  width: 55%;
  left: 22%;
  min-height: 300px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

/* .spbiao {
  width: 70%;
  left: 12%;
  min-height: 300px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
} */

.qudanbiao {
  width: 85%;
  left: 8%;
  min-height: 300px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

div#el-popover-9739.el-popover.el-popper {
  width: 70%;
  margin-left: 15%;
  margin-top: 10%;
}

.xi span {
  width: 9%;
  display: inline-block;
  text-align: center;
}

ul {
  display: inline-block;
  width: 100%;
  margin: 0;
  list-style: none;
  padding: 0;
}

.xi {
  min-height: 30px;
  line-height: 30px;
  border: 1px solid lightgray;
}

.dank {
  width: 100%;
  height: 30px;
  border: 1px solid gainsboro;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
}

.notice {
  width: 65%;
  height: 50px;
  top: 25px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}

.notice /deep/ .el-card__body {
  padding: 0;
}

/*使文字和公告图片在一行*/
.notice_item {
  height: 50px;
  line-height: 50px;
  background: #50a3e5;
  color: white;
  white-space: nowrap;
  font-size: 14px;
  /* background-color: #fff; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notice_item img {
  width: 40px;
  /*height: 16px;*/
  margin: 0 44px 0 32px;
}

.el-card {
  border: none;
}
</style>
