<template>
  <div class="con">
    <div class="head">
      <span style="float: left; line-height: 55px">
        <i class="el-icon-arrow-left" @click="$router.back(-1)"></i>{{ title }}</span
      >
      <img :src="imge" width="40" height="40" class="touimg" />
      <!-- <el-card class="notice">
        <el-carousel
          indicator-position="none"
          arrow="never"
          direction="horizontal"
          :interval="6000"
          loop="true"
        >
          <el-carousel-item v-for="i in newlist" :key="i" class="notice_item">
            <i class="el-icon-s-flag" style="color: red"></i><span @click="detail(i)" style="cursor: pointer"> {{ i.title }}</span>
          </el-carousel-item>
        </el-carousel>
      </el-card> -->
      <h2 class="spname">{{ shopname }}[{{ dptype }}]</h2>
    </div>
    <div v-html="details" style="padding: 3%"></div>
  </div>
</template>

<script>
export default {
  name: "proinfo",
  data() {
    return {
      name: "",
      pwd: "",
      imge: "",
      shop_id: "",
      shopname: "",
      url: "http://shouyin.xuanfengxia.com",
      dptype: "", //店铺类型
      details: "",
      newlist: [],
      title: "",
    };
  },
  mounted() {
    this.details = this.$route.params.details;
    this.title = this.$route.params.title;
    this.shopname = localStorage.getItem("shopname");
    this.imge = localStorage.getItem("imge");
    var type_id = localStorage.getItem("type_id");
    if (type_id == 3) {
      this.dptype = "易货店";
    } else if (type_id == 5) {
      this.dptype = "折扣仓";
    }
  },
  methods: {
    out() {
      localStorage.clear();
      this.$router.replace({
        name: "login",
      });
    },
  },
  computed: {},
};
</script>
<style scoped>
.con {
  text-align: left;
}
.a {
  text-decoration: none;
}
.el-menu-item {
  font-size: 20px;
}
.head {
  text-align: right;
  background: #50a3e5;
  color: white;
  padding: 10px;
  margin: 15px;
}
.touimg {
  width: 45px;
  height: 45px;
  vertical-align: middle;
  border-radius: 50%;
}
.spname {
  font-size: 18px;
  display: inline-block;
  margin-left: 15px;
}
.notice {
  width: 65%;
  height: 50px;
  top: 25px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}
.notice /deep/ .el-card__body {
  padding: 0;
}
/*使文字和公告图片在一行*/
.notice_item {
  height: 50px;
  line-height: 50px;
  background: #50a3e5;
  color: white;
  white-space: nowrap;
  font-size: 14px;
  /* background-color: #fff; */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.notice_item img {
  width: 40px;
  /*height: 16px;*/
  margin: 0 44px 0 32px;
}
.el-card {
  border: none;
}
</style>
