<template>
  <div class="about">
    <div class="head">
      <span style="float: left; line-height: 55px">
        <i class="el-icon-arrow-left" @click="$router.back(-1)"></i>退货明细</span
      >
      <div id="box" ref="box">
        <div class="marquee-box" ref="marquee" @mouseover="menter" @mouseleave="mleave">
          <p ref="cmdlist" id="pWidth" @click="detail(i)">
            <i class="el-icon-s-flag" style="color: red"></i>{{ gg }}
          </p>
        </div>
      </div>
      <img :src="imge" width="40" height="40" class="touimg" />
      <!-- <el-card class="notice">
        <el-carousel
          indicator-position="none"
          arrow="never"
          direction="horizontal"
          :interval="6000"
          :loop="true"
        >
          <el-carousel-item v-for="(i, ind) in newlist" :key="ind" class="notice_item">
            <i class="el-icon-s-flag" style="color: red"></i
            ><span @click="detail(i)" style="cursor: pointer"> {{ i.title }}</span>
          </el-carousel-item>
        </el-carousel>
      </el-card> -->
      <h2 class="spname">{{ shopname }}[{{ dptype }}]</h2>
    </div>
    <el-date-picker
      v-model="start"
      type="date"
      placeholder="开始日期"
      format="yyyy 年 MM 月 dd 日"
      value-format="yyyy-MM-dd"
      style="width: 100px"
    >
    </el-date-picker>
    至
    <el-date-picker
      v-model="end"
      type="date"
      placeholder="结束日期"
      format="yyyy 年 MM 月 dd 日"
      value-format="yyyy-MM-dd"
      style="width: 100px"
    >
    </el-date-picker>
    <!-- <label for="" style="margin-left:20px">账号：</label>
    <el-input
      placeholder="请输入内容"
      v-model="input"
      style="width:150px"
      clearable
    >
    </el-input> -->
    <label for="">会员卡号</label>
    <el-input
      v-model="huiyuan"
      placeholder="请输入内容"
      style="width: 150px"
      @keyup.enter.native="searchEnterFunhuiyuan"
    ></el-input>
    <el-button type="primary" icon="el-icon-search" @click="ok"></el-button>
    <label for="" style="margin-left: 20px">商品名称：</label>
    <el-input placeholder="请输入内容" v-model="cardinput" style="width: 150px" clearable>
    </el-input>
    <label for="" style="margin-left: 20px">姓名：</label>
    <el-input placeholder="请输入内容" v-model="nameinput" style="width: 100px" clearable>
    </el-input>
    <label for="" style="margin-left: 20px">账号：</label>
    <el-input placeholder="请输入内容" v-model="input" style="width: 150px" clearable>
    </el-input>
    <el-button type="warning" @click="getUser" style="margin-left: 20px">查看</el-button>
    <br /><br />
    <!-- 内容区域 -->
    <el-main id="main" class="main">
      <Breadcrumb></Breadcrumb>

      <!-- 表格 -->
      <div class="table">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column fixed prop="id" label="ID"> </el-table-column>
          <el-table-column prop="nickname" label="用户名"> </el-table-column>
          <el-table-column prop="account" label="电话"> </el-table-column>
          <el-table-column prop="cardnum" label="卡号"> </el-table-column>
          <el-table-column prop="goods_name" label="商品名"> </el-table-column>
          <el-table-column property="goods_pic" label="商品图">
            <template slot-scope="scope">
              <img :src="scope.row.goods_pic" @error="defImg()" width="40" height="40" />
            </template>
          </el-table-column>
          <el-table-column prop="refund_num" label="退货数"> </el-table-column>
          <el-table-column prop="refund_money" label="退货金额"> </el-table-column>
          <el-table-column fixed="right" prop="create_time" label="创建时间">
          </el-table-column>
        </el-table>
      </div>
      <!-- /End 表格 -->
      <div>
        <el-popover
          placement="left"
          width="780px"
          trigger="manual"
          :offset="300"
          v-model="visibleka"
        >
          <div class="spbiao">
            <el-button
              slot="reference"
              @click="visibleka = !visibleka"
              style="float: right"
              ><i class="el-icon-close"></i
            ></el-button>
            <el-table
              :data="kaData"
              @row-click="clickRowka"
              ref="moviesTable"
              height="520"
            >
              <el-table-column
                width="150"
                property="card_id"
                label="编码"
              ></el-table-column>
              <el-table-column
                width="150"
                property="cardnum"
                label="卡号"
              ></el-table-column>
              <el-table-column
                width="100"
                property="nickname"
                label="姓名"
              ></el-table-column>
              <el-table-column
                width="100"
                property="money"
                label="余额"
              ></el-table-column>
            </el-table>
          </div>
        </el-popover>
      </div>

      <!-- <Pagination></Pagination> -->

      <!-- 分页 -->
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[100, 300, 500]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- /End 分页 -->
    </el-main>
    <!-- /End 内容区域 -->
  </div>
</template>

<script>
// import axios from "axios";
export default {
  name: "tuihuo",
  data() {
    return {
      defaultImg: require("../../static/1.png"),
      start: "",
      end: "",
      input: "",
      huiyuan: "",
      cardinput: "",
      nameinput: "",
      spnameinput: "",
      onepas: "", //修改密码
      twopas: "", //确认密码
      gridData: [],
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false, //充值
      visibles: false, //修改密码
      visibleka: false, //卡号弹框
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 100, // 每页显示条数
      yingshou: "", //收款金额
      zhifu: "", //充值金额
      imge: "",
      shopname: "",
      cardid: "",
      kaData: [], //卡号列表
      dptype: "",
      newlist: [],
      timer: "", //计时器
      pwidth: 0, //公告文本的宽度
      value: 0,
      gg: "",
      i: "",
      titleStr: "",
      // windowWidth:'', // box宽度
      windowWidth: document.documentElement.clientWidth / 2.5,
    };
  },
  mounted() {
    let element = this.$refs.cmdlist;
    this.pwidth = document.defaultView.getComputedStyle(element, "").width.split("px");
    this.timer = setInterval(this.clickCommend, 20);
    this.getUser();
    this.shopname = localStorage.getItem("shopname");
    this.imge = localStorage.getItem("imge");
    var type_id = localStorage.getItem("type_id");
    if (type_id == 3) {
      this.dptype = "易货店";
    } else if (type_id == 5) {
      this.dptype = "折扣仓";
    }
    this.$axios.post(this.$api.new, {}).then((res) => {
      if (res.code == 1) {
        this.newlist = res.data;
        this.gg = this.newlist[0].title;
        this.i = 0
      }
    });
    // this.$axios.post(this.$api.shopinfo, {}).then((res) => {
    //   if (res.code == 1) {
    //     console.log(res.data.shop_name);
    //     this.shopname = res.data.shop_name;
    //     this.imge = res.data.logo;
    //     // this.sdyingshou = res.data.total_price
    //     // this.sdshishou = res.data.total_price
    //   }
    // });
  },
  watch: {
    value(newValue) {
      let allWidth = parseInt(this.windowWidth) + parseInt(this.pwidth[0]);
      // console.log(oldValue);
      if (newValue <= -allWidth) {
        this.$refs.cmdlist.style.marginLeft = this.windowWidth + "px";
        this.value = 0;
      }
    },
  },
  methods: {
    clickCommend() {
      // console.log(e);
      let _this = this;
      this.$nextTick(() => {
        this.value -= 1;
        this.$refs.cmdlist.style.marginLeft = _this.windowWidth + this.value + "px";
      });
    },
    menter() {
      clearInterval(this.timer);
    },
    mleave() {
      this.timer = setInterval(this.clickCommend, 20);
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    detail(i) {
      this.$router.push({
        name: "proinfo",
        params: { details: this.newlist[i].details, title: this.newlist[i].title },
      });
    },
    defImg() {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },

    searchEnterFunhuiyuan: function (e) {
      //会员查询
      var keyCode = window.event ? e.keyCode : e.which;
      if (keyCode == 13) {
        this.visibleka = true;
        console.log(this.visibleka);
        //搜索会员信息
        // axios.defaults.withCredentials = false;
        this.$axios
          .post(this.$api.kahao, {
            number: this.huiyuan,
          })
          .then((res) => {
            console.log(res.data);
            this.visibleka = true;
            if (res.code == 1) {
              // this.huiyuan = res.data.cardnum + "--" + res.data.nickname;
              this.kaData = res.data;
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
      }
    },
    ok() {
      console.log(this.visibleka);
      //搜索会员信息
      // axios.defaults.withCredentials = false;
      this.$axios
        .post(this.$api.kahao, {
          number: this.huiyuan,
        })
        .then((res) => {
          console.log(res.data);
          this.visibleka = true;
          if (res.code == 1) {
            // this.huiyuan = res.data.cardnum + "--" + res.data.nickname;
            this.kaData = res.data;
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        });
    },
    clickRowka(row) {
      //点击商品详情显示
      this.$refs.moviesTable.toggleRowSelection(row);
      // var str =
      //   '{ ordersn: row.goods_id, guige: row.guige,price: row.price,title: row.title,goods_num:"1",price:row.price,goods_zhe:"10",jine:row.price,goods_id:row.goods_id}';
      // console.log(eval("(" + str + ")"));
      // this.tableData.push(eval("(" + str + ")"));
      // console.log(this.tableData);
      console.log(row.cardnum);
      this.huiyuan = row.cardnum;
      this.visibleka = false;
      this.uid = row.uid;
      // this.visibleg = false;
    },
    // checkTime(i){
    //   if(i<10){
    //     i='0'+i
    //     }
    //     return i
    //     },
    getUser() {
      this.$axios
        .post(this.$api.tui, {
          cardnum: this.huiyuan, // 卡号
          goods_name: this.cardinput, // 商品名
          account: this.input, //用户账号
          nickname: this.nameinput, //用户姓名
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
          start_time: this.start,
          end_time: this.end,
        //   id: this.$route.query.id,
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.count; // 总条目数
        });
    },

    handleClick(row) {
      console.log(row.id);
      this.visible = true;
      this.cardid = row.id;
    },
    handleClicks(row) {
      console.log(row.id);
      this.visibles = true;
      this.cardid = row.id;
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
    jiesuan() {
      //最终商品结算
      this.$confirm("请您确定结算内容, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.addvipcard, {
              id: this.cardid,
              collection_money: this.yingshou,
              recharge_money: this.zhifu,
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visible = false;
                location.reload(); //刷新页面
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.visible = false;
        });
    },
    nopay() {
      //取消结算
      this.$message({
        message: "取消结算",
        type: "warning",
      });
      this.visible = false;
    },
    xiugai() {
      //最终商品结算
      this.$confirm("请您确定修改密码, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.xiupwd, {
              id: this.cardid,
              password: this.onepas,
              confirm_password: this.twopas,
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visibles = false;
                // location.reload() //刷新页面
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.visibles = false;
        });
    },
    noxiu() {
      //取消结算
      this.$message({
        message: "取消修改",
        type: "warning",
      });
      this.visibles = false;
    },
  },
  computed: {},
};
</script>
<style scoped>
#box {
  width: 60%;
  vertical-align: middle;
  display: inline-block;
  margin-right: 5%;
  background: #50a3e5;
  color: #fff;
  white-space: nowrap;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.marquee-box {
  overflow: auto;
}
#pWidth {
  width: 60%;
  height: 50px;
  padding: 0;
  margin: 0;
  line-height: 50px;
  display: block;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  font-family: 微软雅黑;
  font-size: 14px;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.spbiao {
  width: 40%;
  left: 22%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 5%;
}
.head {
  text-align: right;
  background: #50a3e5;
  color: white;
  padding: 10px;
  margin: 15px;
}
.touimg {
  width: 45px;
  height: 45px;
  vertical-align: middle;
  border-radius: 50%;
}
.spname {
  font-size: 18px;
  display: inline-block;
  margin-left: 15px;
}
.notice {
  width: 65%;
  height: 50px;
  top: 25px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}
.notice /deep/ .el-card__body {
  padding: 0;
}
/*使文字和公告图片在一行*/
.notice_item {
  height: 50px;
  line-height: 50px;
  background: #50a3e5;
  color: white;
  white-space: nowrap;
  font-size: 14px;
  /* background-color: #fff; */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.notice_item img {
  width: 40px;
  /*height: 16px;*/
  margin: 0 44px 0 32px;
}
.el-card {
  border: none;
}
</style>
