<template>
  <div class="about">
    <div class="head">
      <span style="float: left; line-height: 55px">
        <i class="el-icon-arrow-left" @click="$router.back(-1)"></i>数据统计</span
      >
      <div id="box" ref="box">
        <div class="marquee-box" ref="marquee" @mouseover="menter" @mouseleave="mleave">
          <p ref="cmdlist" id="pWidth" @click="detail(i)">
            <i class="el-icon-s-flag" style="color: red"></i>{{ gg }}
          </p>
        </div>
      </div>
      <img :src="imge" width="40" height="40" class="touimg" />
      <!-- <el-card class="notice">
        <el-carousel
          indicator-position="none"
          arrow="never"
          direction="horizontal"
          :interval="6000"
          :loop="true"
        >
           <el-carousel-item v-for="(i, ind) in newlist" :key="ind"  class="notice_item">
            <i class="el-icon-s-flag" style="color: red"></i
            ><span @click="detail(i)" style="cursor: pointer"> {{ i.title }}</span>
          </el-carousel-item>
        </el-carousel>
      </el-card> -->
      <h2 class="spname">{{ shopname }}</h2>
    </div>
    <!-- <el-date-picker
      v-model="start"
      type="date"
      placeholder="开始日期"
      format="yyyy 年 MM 月 dd 日"
      value-format="yyyy-MM-dd"
      style="width: 100px"
    >
    </el-date-picker>
    至
    <el-date-picker
      v-model="end"
      type="date"
      placeholder="结束日期"
      format="yyyy 年 MM 月 dd 日"
      value-format="yyyy-MM-dd"
      style="width: 100px"
    >
    </el-date-picker> -->
    <!-- <label for="" style="margin-left:20px">手机号：</label>
    <el-input
      placeholder="请输入内容"
      v-model="input"
      style="width:200px"
      clearable
    >
    </el-input>
    <label for="" style="margin-left:20px">卡号：</label>
    <el-input
      placeholder="请输入内容"
      v-model="cardinput"
      style="width:200px"
      clearable
    >
    </el-input>
    <label for="" style="margin-left:20px">姓名：</label>
    <el-input
      placeholder="请输入内容"
      v-model="nameinput"
      style="width:150px"
      clearable
    >
    </el-input>
    <el-button type="warning" @click="getUser" style="margin-left:20px"
      >查看</el-button
    > -->
    <br /><br />
    <!-- 内容区域 -->
    <el-main id="main" class="main">
      <el-row :gutter="12">
        <el-col :span="6">
          <el-card shadow="always">今日销售金额：<br />{{ todaylingzong }}</el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always">今日充值金额：<br />{{ todaychongzong }}</el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always"> 总销售金额：<br />{{ lingzong }} </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always"> 总充值金额：<br />{{ chongzong }} </el-card>
        </el-col>
      </el-row>
    </el-main>
    <!-- /End 内容区域 -->
    <!-- <iframe
      src="https://zlyh.jinfbi.com/index.php?s=/store/index/dblog.html"
      width="1200"
      height="300"
      frameborder="0"
      scrolling="auto"
      style="position:absolute;top: -300px;left: 0px;"
    ></iframe> -->
  </div>
</template>

<script>
// import axios from "axios";
export default {
  name: "total",
  data() {
    return {
      start: "",
      end: "",
      input: "",
      cardinput: "",
      nameinput: "",
      imge: "",
      shopname: "",
      lingzong: "0.00",
      todaylingzong: "0.00",
      chongzong: "0.00",
      todaychongzong: "0.00",
      newlist: [],
      timer: "", //计时器
      pwidth: 0, //公告文本的宽度
      value: 0,
      gg: "",
      i: "",
      titleStr: "",
      // windowWidth:'', // box宽度
      windowWidth: document.documentElement.clientWidth / 2.5,
    };
  },
  mounted() {
    let element = this.$refs.cmdlist;
    this.pwidth = document.defaultView.getComputedStyle(element, "").width.split("px");
    this.timer = setInterval(this.clickCommend, 20);
    this.getUser();
    this.shopname = localStorage.getItem("shopname");
    this.imge = localStorage.getItem("imge");
    this.$axios.post(this.$api.new, {}).then((res) => {
      if (res.code == 1) {
        this.newlist = res.data;
        this.gg = this.newlist[0].title;
        this.i = 0
      }
    });
    // this.$axios.post(this.$api.shopinfo, {}).then((res) => {
    //   if (res.code == 1) {
    //     console.log(res.data.shop_name);
    //     this.shopname = res.data.shop_name;
    //     this.imge = res.data.logo;
    //     // this.sdyingshou = res.data.total_price
    //     // this.sdshishou = res.data.total_price
    //   }
    // });
  },
  watch: {
    value(newValue, oldValue) {
      let allWidth = parseInt(this.windowWidth) + parseInt(this.pwidth[0]);
      console.log(oldValue);
      if (newValue <= -allWidth) {
        this.$refs.cmdlist.style.marginLeft = this.windowWidth + "px";
        this.value = 0;
      }
    },
  },
  methods: {
    clickCommend(e) {
      console.log(e);
      let _this = this;
      this.$nextTick(() => {
        this.value -= 1;
        this.$refs.cmdlist.style.marginLeft = _this.windowWidth + this.value + "px";
      });
    },
    menter() {
      clearInterval(this.timer);
    },
    mleave() {
      this.timer = setInterval(this.clickCommend, 20);
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
   detail(i) {
      this.$router.push({
        name: "proinfo",
        params: { details: this.newlist[i].details, title: this.newlist[i].title },
      });
    },
    getUser() {
      this.$axios.post(this.$api.huizong).then((res) => {
        // this.tableData = res.data.list; // 表格数据
        // this.total = res.data.count; // 总条目数
        this.lingzong = res.data.total_sale_money; //	总销售金额
        this.chongzong = res.data.total_recharge_money; //总充值金额
        this.todaylingzong = res.data.today_sale_money; //	今日销售金额
        this.todaychongzong = res.data.today_recharge_money; //今日充值金额
      });
    },
    stateFormat(row) {
      if (row.refund_status === 1) {
        return "已退货";
      } else {
        return "未退货";
      }
    },
    handleClick(row, index) {
      console.log(row);
      console.log(index);
      this.$axios
        .post(this.$api.handleClick, {
          order_id: row.order_id,
        })
        .then((res) => {
          this.visible = true;
          this.gridData = res.data; // 表格数据
          // console.log(res);
          // for (var i = 0; i < res.data.length; i++) {
          // 	console.log(res.data[i].refund_status);
          // 	if (res.data[i].refund_status == 1) {}
          // }
          // console.log(res.data.refund_status);
        });
    },
    handleClicks(row, index) {
      console.log(row);
      console.log(index);
      var msg = "您真的确定要退货吗？\n\n请确认！";
      if (confirm(msg) == true) {
        this.$axios
          .post(this.$api.tuihuo, {
            id: row.id,
          })
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                message: res.msg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        // return true;
      } else {
        return false;
      }
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
#box {
  width: 60%;
  vertical-align: middle;
  display: inline-block;
  margin-right: 5%;
  background: #50a3e5;
  color: #fff;
  white-space: nowrap;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.marquee-box {
  overflow: auto;
}
#pWidth {
  width: 60%;
  height: 50px;
  padding: 0;
  margin: 0;
  line-height: 50px;
  display: block;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  font-family: 微软雅黑;
  font-size: 14px;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.spbiao {
  width: 70%;
  left: 12%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

.head {
  text-align: right;
  background: #50a3e5;
  color: white;
  padding: 10px;
  margin: 15px;
}

.touimg {
  width: 45px;
  height: 45px;
  vertical-align: middle;
  border-radius: 50%;
}

.spname {
  font-size: 18px;
  display: inline-block;
  margin-left: 15px;
}
.notice {
  width: 65%;
  height: 50px;
  top: 25px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}
.notice /deep/ .el-card__body {
  padding: 0;
}
/*使文字和公告图片在一行*/
.notice_item {
  height: 50px;
  line-height: 50px;
  background: #50a3e5;
  color: white;
  white-space: nowrap;
  font-size: 14px;
  /* background-color: #fff; */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.notice_item img {
  width: 40px;
  /*height: 16px;*/
  margin: 0 44px 0 32px;
}
.el-card {
  border: none;
}
</style>
