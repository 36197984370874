<template>
  <div class="con">
    <div class="head">
      <div id="box" ref="box">
        <div class="marquee-box" ref="marquee" @mouseover="menter" @mouseleave="mleave">
          <p ref="cmdlist" id="pWidth" @click="detail(i)">
            <i class="el-icon-s-flag" style="color: red"></i>{{ gg }}
          </p>
        </div>
      </div>
      <img :src="imge" width="40" height="40" class="touimg" />
      <!-- <el-card class="notice">
        <el-carousel
          indicator-position="none"
          arrow="never"
          direction="horizontal"
          :interval="6000"
          :loop="true"
        >
          <el-carousel-item v-for="(i, ind) in newlist" :key="ind" class="notice_item">
            <i class="el-icon-s-flag" style="color: red"></i
            ><span @click="detail(i)" style="cursor: pointer"> {{ i.title }}</span>
          </el-carousel-item>
        </el-carousel>
      </el-card> -->

      <h2 class="spname">{{ shopname }}[{{ dptype }}]</h2>
    </div>
    <el-col :span="12">
      <el-menu default-active="2" class="el-menu-vertical-demo">
        <router-link to="charts" class="a">
          <el-menu-item index="1">
            <i class="el-icon-goods"></i>
            <span slot="title">统计图表</span>
          </el-menu-item>
        </router-link>
        <router-link to="retail" class="a">
          <el-menu-item index="2">
            <i class="el-icon-goods"></i>
            <span slot="title">商品零售</span>
          </el-menu-item>
        </router-link>
        <router-link to="orderinfo" class="a">
          <el-menu-item index="3">
            <i class="el-icon-notebook-2"></i>
            <span slot="title">订单列表</span>
          </el-menu-item>
        </router-link>
        <router-link to="shopinfo" class="a">
          <el-menu-item index="4">
            <i class="el-icon-date"></i>
            <span slot="title">消费记录</span>
          </el-menu-item>
        </router-link>
        <router-link to="vipcard" class="a">
          <el-menu-item index="5">
            <i class="el-icon-collection-tag"></i>
            <span slot="title">卡号列表</span>
          </el-menu-item></router-link
        >
        <router-link to="appointment" class="a">
          <el-menu-item index="6">
            <i class="el-icon-notebook-2"></i>
            <span slot="title">预约订单</span>
          </el-menu-item>
        </router-link>
        <router-link to="tuihuo" class="a">
          <el-menu-item index="7">
            <i class="el-icon-notebook-2"></i>
            <span slot="title">退货记录</span>
          </el-menu-item>
        </router-link>
        <router-link to="vipcardinfo" class="a">
          <el-menu-item index="8">
            <i class="el-icon-position"></i>
            <span slot="title">易货额充值记录</span>
          </el-menu-item></router-link
        >
        <router-link to="feelist" class="a">
          <el-menu-item index="9">
            <i class="el-icon-s-check"></i>
            <span slot="title">服务费充值记录</span>
          </el-menu-item>
        </router-link>
      </el-menu>
    </el-col>
    <el-col :span="12">
      <el-menu default-active="2" class="el-menu-vertical-demo">
        <router-link to="splist" class="a">
          <el-menu-item index="1">
            <i class="el-icon-menu"></i>
            <span slot="title">商品列表</span>
          </el-menu-item>
        </router-link>
        <router-link to="bankcard" class="a">
          <el-menu-item index="2">
            <i class="el-icon-s-check"></i>
            <span slot="title">银行卡列表</span>
          </el-menu-item>
        </router-link>
        <router-link to="dcash" class="a">
          <el-menu-item index="3">
            <i class="el-icon-menu"></i>
            <span slot="title">提现记录</span>
          </el-menu-item>
        </router-link>
        <!-- <router-link to="total" class="a">
          <el-menu-item index="4">
            <i class="el-icon-s-order"></i>
            <span slot="title">销售类别</span>
          </el-menu-item>
        </router-link> -->
        <!-- :href="
            'https://zlyh.jinfbi.com/index.php?s=/mcenter/member/dblog&shop_id=' + shop_id
          " -->
        <a
          :href="
            ' https://www.zhilongyihuo.com/mcenter/member/dblog&shop_id=' +
            shop_id +
            '&user_id=' +
            user_id
          "
          target="view_window"
          class="a"
        >
          <el-menu-item index="5">
            <i class="el-icon-location"></i>
            <span slot="title">调拨记录</span>
          </el-menu-item>
        </a>
        <a
          :href="
            ' https://www.zhilongyihuo.com/index.php?s=/mcenter/member/card&cid=' +
            shop_id +
            '&user_id=' +
            user_id
          "
          target="view_window"
          class="a"
        >
          <el-menu-item index="6">
            <i class="el-icon-location"></i>
            <span slot="title">卡片录入</span>
          </el-menu-item>
        </a>

     <a
          :href="
            ' https://www.zhilongyihuo.com/html/unishop/#/pages/member/goodsinfo?tk=' +
            ((user_id*3-131)*19) +
            '&user_id=' +
            user_id+
            '&shop_id=' +
            shop_id
          "
          target="view_window"
          class="a"
        >
          <el-menu-item index="6">
            <i class="el-icon-location"></i>
            <span slot="title">库存管理</span>
          </el-menu-item>
        </a>
        
        <!-- <router-link to="login" class="a"> -->
            <router-link to="linelist" class="a">
          <el-menu-item index="7">
            <i class="el-icon-notebook-2"></i>
            <span slot="title" >线上订单</span>
          </el-menu-item>

          
        </router-link>

  
        
        <router-link to="pro" class="a">
          <el-menu-item index="8">
            <i class="el-icon-s-order"></i>
            <span slot="title" style="color: red">常见问题</span>
          </el-menu-item>
        </router-link>
        <router-link to="news" class="a">
          <el-menu-item index="9">
            <i class="el-icon-s-order"></i>
            <span slot="title" style="color: red">历史公告</span>
          </el-menu-item>
        </router-link>
        <el-menu-item index="9" @click="out">
          <i class="el-icon-coordinate"></i>
          <span slot="title">退出</span>
        </el-menu-item>
        <!-- </router-link> -->
      </el-menu>
    </el-col>
  </div>
</template>

<script>
export default {
  name: "pagemenu",
  data() {
    return {
      name: "",
      pwd: "",
      imge: "",
      shop_id: "",
      user_id: "",
      shopname: "",
      url: "http://shouyin.xuanfengxia.com",
      dptype: "", //店铺类型
      newlist: [],
      value: 0,
      timer: "", //计时器
      pwidth: 0, //公告文本的宽度
      gg: "",
      i: "",
      titleStr: "",
      // windowWidth:'', // box宽度
      windowWidth: document.documentElement.clientWidth / 2.5, // 设备屏幕的宽度
    };
  },
  mounted() {
    // this.windowWidth= this.$refs.box.clientWidth+"px"
    let element = this.$refs.cmdlist;
    this.pwidth = document.defaultView.getComputedStyle(element, "").width.split("px");
  //  this.timer = setInterval(this.clickCommend, 20);
    this.shop_id = localStorage.getItem("shop_id");
    this.user_id = localStorage.getItem("user_id");
    this.$axios.post(this.$api.shopinfo, {}).then((res) => {
      if (res.code == 1) {
        console.log(res.data.shop_name);
        this.shopname = res.data.shop_name;
        this.imge = res.data.logo;
        localStorage.setItem("shopname", res.data.shop_name);
        localStorage.setItem("imge", res.data.logo);
        // this.sdyingshou = res.data.total_price
        // this.sdshishou = res.data.total_price
      }
    });
    this.$axios.post(this.$api.new, {}).then((res) => {
      if (res.code == 1) {
        this.newlist = res.data;
        this.gg = this.newlist[0].title;
        this.i = 0;
      }
    });
    var type_id = localStorage.getItem("type_id");
    if (type_id == 3) {
      this.dptype = "易货店";
    } else if (type_id == 5) {
      this.dptype = "折扣仓";
    }
  },
  watch: {
    value(newValue) {
      let allWidth = parseInt(this.windowWidth) + parseInt(this.pwidth[0]);
      // console.log(oldValue);
      if (newValue <= -allWidth) {
        this.$refs.cmdlist.style.marginLeft = this.windowWidth + "px";
        this.value = 0;
      }
    },
  },
  methods: {
    clickCommend() {
      // console.log(e);
      let _this = this;
      this.$nextTick(() => {
        this.value -= 1;
        this.$refs.cmdlist.style.marginLeft = _this.windowWidth + this.value + "px";
      });
    },
    menter() {
      clearInterval(this.timer);
    },
    mleave() {
     // this.timer = setInterval(this.clickCommend, 20);
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    out() {
      localStorage.clear();
      this.$router.replace({
        name: "login",
      });
    },
    detail(i) {
      this.$router.push({
        name: "proinfo",
        params: { details: this.newlist[i].details, title: this.newlist[i].title },
      });
    },
  },
  computed: {},
};
</script>
<style scoped>
#box {
  width: 60%;
  vertical-align: middle;
  display: inline-block;
  margin-right: 5%;
  background: #50a3e5;
  color: #fff;
  white-space: nowrap;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.marquee-box {
  overflow: auto;
}
#pWidth {
  width: 60%;
  height: 50px;
  padding: 0;
  margin: 0;
  line-height: 50px;
  display: block;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  font-family: 微软雅黑;
  font-size: 14px;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.a {
  text-decoration: none;
}
.el-menu-item {
  font-size: 20px;
}
.head {
  text-align: right;
  background: #50a3e5;
  color: white;
  padding: 10px;
  margin: 15px;
}
.touimg {
  width: 45px;
  height: 45px;
  vertical-align: middle;
  border-radius: 50%;
}
.spname {
  font-size: 18px;
  display: inline-block;
  margin-left: 15px;
}
/**/
.notice {
  width: 65%;
  height: 50px;
  top: 25px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}
.notice /deep/ .el-card__body {
  padding: 0;
}
/*使文字和公告图片在一行*/
.notice_item {
  height: 50px;
  line-height: 50px;
  background: #50a3e5;
  color: white;
  white-space: nowrap;
  font-size: 14px;
  /* background-color: #fff; */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.notice_item img {
  width: 40px;
  /*height: 16px;*/
  margin: 0 44px 0 32px;
}
.el-card {
  border: none;
}
</style>
