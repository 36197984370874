<template>
  <div class="about">
    <div class="head">
      <span style="float: left; line-height: 55px">
        <i class="el-icon-arrow-left" @click="$router.back(-1)"></i>卡号列表</span>
      <div id="box" ref="box">
        <div class="marquee-box" ref="marquee" @mouseover="menter" @mouseleave="mleave">
          <p ref="cmdlist" id="pWidth" @click="detail(i)">
            <i class="el-icon-s-flag" style="color: red"></i>{{ gg }}
          </p>
        </div>
      </div>
      <img :src="imge" width="40" height="40" class="touimg" />
      <!-- <el-card class="notice">
        <el-carousel
          indicator-position="none"
          arrow="never"
          direction="horizontal"
          :interval="6000"
          :loop="true"
        >
          <el-carousel-item v-for="(i, ind) in newlist" :key="ind" class="notice_item">
            <i class="el-icon-s-flag" style="color: red"></i
            ><span @click="detail(i)" style="cursor: pointer"> {{ i.title }}</span>
          </el-carousel-item>
        </el-carousel>
      </el-card> -->
      <h2 class="spname">{{ shopname }}[{{ dptype }}]</h2>
    </div>
    <!-- <el-date-picker v-model="start" type="date" placeholder="开始日期">
		</el-date-picker> 至
		<el-date-picker v-model="end" type="date" placeholder="结束日期">
		</el-date-picker>-->
    <label for="" style="margin-left: 20px">姓名：</label>
    <el-input placeholder="请输入内容" v-model="nameinput" style="width: 150px" clearable></el-input>
    <label for="" style="margin-left: 20px">账号：</label>
    <el-input placeholder="请输入内容" v-model="input" style="width: 200px" clearable>
    </el-input>
    <label for="" style="margin-left: 20px">卡号：</label>
    <el-input placeholder="请输入内容" v-model="cardinput" style="width: 200px" clearable>
    </el-input>
    <label for="" style="margin-left: 20px">姓名：</label>
    <!-- <el-input placeholder="请输入内容" v-model="nameinput" style="width: 150px" clearable></el-input> -->
    <el-input placeholder="请输入内容" v-model="nameinput" readonly="true" @click.native="inputc" style="width: 150px"
      clearable>
    </el-input>
    <el-button type="warning" @click="getUser" style="margin-left: 20px">查看</el-button>
    <el-button type="warning" @click="handleExcel" style="margin-left: 10px">导出</el-button>
    <br /><br />
    <!-- 内容区域 -->
    <el-main id="main" class="main">
      <Breadcrumb></Breadcrumb>
      <div style="text-align: left">
        <h5>总易货额：{{ total_money }}元</h5>
        <h5>总服务费：{{ total_moneyfee }} 元</h5>
        <!-- <el-input v-model="lingshou" placeholder="请输入内容" style="width: 200px;" ref='sd' id="sd" disabled></el-input> -->
        <!-- <el-input v-model="tuihuo" placeholder="请输入内容" style="width: 200px;" disabled></el-input> -->
      </div>
      <!-- 表格 -->
      <div class="table">
        <el-table :data="tableData" border style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }"
          @sort-change="changeSort">
          <el-table-column type="expand" label="展开更多">
            <template slot-scope="props">
              <el-form label-position="left" inline class="demo-table-expand">
                <!-- <el-form-item label="开卡时间">
                  <span>{{ props.row.ttime }}</span>
                </el-form-item> -->
                <el-form-item label="服务费">
                  <span>{{ props.row.moneyfee }}</span>
                </el-form-item>
                <!-- <el-form-item label="冻结额度">
                  <span>{{ props.row.dongjie }}</span>
                </el-form-item> -->
                <el-form-item label="需求商品">
                  <span>{{ props.row.getzy }}</span>
                </el-form-item>
                <el-form-item label="核价员">
                  <span>{{ props.row.hejiayuan }}</span>
                </el-form-item>
                <el-form-item label="易货商品">
                  <span>{{ props.row.yhsp }}</span>
                </el-form-item>
                <el-form-item label="业务员">
                  <span>{{ props.row.yw }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="ID" width="100"> </el-table-column>
          <el-table-column prop="nickname" label="用户名" width="120">
          </el-table-column>
          <el-table-column prop="account" label="电话" width="150">
          </el-table-column>
          <el-table-column prop="cardnum" label="卡号" min-width="120">
          </el-table-column>
          <el-table-column prop="ttime" label="开卡时间" width="200">
          </el-table-column>
          <el-table-column prop="shop_name" label="所属网点" width="300"> </el-table-column>
          <el-table-column label="卡号类型" prop="card_type" :formatter="stateFormat"></el-table-column>
          <el-table-column label="开卡类型" prop="open_type" :formatter="stateFormatk"></el-table-column>
          <el-table-column prop="money" label="余额" width="100" sortable="custom">
          </el-table-column>
          <!-- <el-table-column
            prop="moneyfee"
            label="服务费"
            width="100"
            sortable="custom"
          >
          </el-table-column> -->
          <!-- <el-table-column
            label="冻结状态"
            prop="qx"
            :formatter="stateFormats"
          ></el-table-column> -->
          <el-table-column prop="dongjie" label="冻结额度"> </el-table-column>
          <!-- <el-table-column prop="getzy" label="需求商品"> </el-table-column> -->
          <!-- <el-table-column prop="hejiayuan" label="核价员"> </el-table-column> -->
          <!-- <el-table-column prop="yhsp" label="易货商品"> </el-table-column> -->
          <!-- <el-table-column prop="yw" label="业务员"> </el-table-column> -->
          <el-table-column label="合同" width="150" height="150">
            <!-- class="demo-image__preview" -->
            <template slot-scope="scope" >
              <el-image style="width: 100px; height: 100px" :src="scope.row.hetong[0]"
                :preview-src-list="scope.row.hetong" @error="defImg()">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="400">
            <template slot-scope="scope">
              <el-button @click="handleClicks(scope.row)">修改密码</el-button>
              <el-button @click="handleClickdj(scope.row)" style="margin: 0">冻结额度</el-button>
              <el-button @click="handleClickn(scope.row)" style="margin: 0">修改姓名</el-button>
              <el-button @click="handleClick(scope.row)" style="margin: 0">充值额度</el-button>
              <el-button @click="handleClickf(scope.row)" style="margin: 0">服务费充值</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- /End 表格 -->
      <div>
        <el-popover placement="left" width="780px" trigger="manual" :offset="300" v-model="visible"><br /><br />
          <div class="spbiao">
            <!-- 收款金额：
            <el-input
              v-model="yingshou"
              placeholder="请输入内容"
              style="width: 300px"
            ></el-input
            ><br />
            <br /> -->
            充值金额：
            <el-input v-model="zhifu" placeholder="请输入内容" style="width: 300px"></el-input><br /><br />
            <span style="color: red">*扣款请输入负值*</span><br /><br />
            充值原因：
            <el-input type="text" placeholder="请输入内容" style="width: 300px" v-model="yuanyin" minlength="10"
              show-word-limit></el-input><br /><br />
            <!-- <el-select v-model="fangshi" placeholder="充值类型">
              <el-option
                v-for="item in options"
                :key="item.value"
                :name="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
            <el-button type="success" @click="jiesuan">确定</el-button>
            <el-button :plain="true" @click="nopay">取消</el-button>
          </div>
        </el-popover>
      </div>
      <el-popover placement="left" width="780px" trigger="manual" :offset="300" v-model="visiblefw"><br /><br />
        <div class="spbiao">
          <el-button :plain="true" @click="noxiu" style="float: right; margin: 3%">取消</el-button><br />
          充值服务费：
          <el-input v-model="fuwu" placeholder="请输入内容" style="width: 200px"></el-input><br /><br />
          微 信 扫 码：
          <el-input type="text" v-model="smfee" placeholder="点击后扫微信付款码支付" style="width: 200px" @focus="changeColor"
            @keyup.enter.native="searchEnterFunpay" autofocus clearable></el-input>
          <br /><span style="color: red">*点击输入框后扫描微信付款码支付*</span>
          <!-- <el-button type="success" @click="jiesuanfw">确定</el-button> -->
        </div>
      </el-popover>
      <div>
        <el-popover placement="left" width="780px" trigger="manual" :offset="300" v-model="visibles"><br /><br />
          <div class="spbiao">
            修改密码：
            <el-input v-model="onepas" placeholder="请输入内容" style="width: 300px" show-password></el-input><br />
            <br />
            确认密码：
            <el-input v-model="twopas" placeholder="请输入内容" style="width: 300px" show-password></el-input><br /><br />
            <el-button type="success" @click="xiugai">确定</el-button>
            <el-button :plain="true" @click="noxiu">取消</el-button>
          </div>
        </el-popover>
      </div>
      <div>
        <el-popover placement="left" width="780px" trigger="manual" :offset="300" v-model="visiblen"><br /><br />
          <div class="spbiao">
            用户姓名：
            <el-input v-model="kaname" placeholder="请输入内容" style="width: 300px"></el-input><br />
            <br />
            <el-button type="success" @click="xiugain">确定</el-button>
            <el-button :plain="true" @click="noxiu">取消</el-button>
          </div>
        </el-popover>
      </div>
      <div>
        <el-popover placement="left" width="780px" trigger="manual" :offset="300" v-model="visibledj"><br /><br />
          <div class="spbiao">
            冻结额度：
            
            <el-input v-model="djmoney" placeholder="请输入内容" style="width: 300px"></el-input><br />
            <el-input type="text" placeholder="冻结原因" style="width: 300px" v-model="djyuanyin" minlength="10"
              show-word-limit></el-input><br /><br />
            <el-button type="success" @click="dongjie">确定</el-button>
            <el-button :plain="true" @click="noxiu">取消</el-button>
          </div>
        </el-popover>
      </div>
      <!-- <Pagination></Pagination> -->

      <!-- 分页 -->
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-sizes="[100, 300, 500]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <!-- /End 分页 -->
    </el-main>
    <!-- /End 内容区域 -->
  </div>
</template>

<script>
// import axios from "axios";
import { Loading } from "element-ui";
export default {
  name: "vipcard",
  data() {
    return {
      start: "",
      defaultImg: require("../../static/1.png"),
      end: "",
      input: "",
      cardinput: "",
      yuanyin: "",
      nameinput: "",
      smfee: "", //扫码
      onepas: "", //修改密码
      twopas: "", //确认密码
      gridData: [],
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false, //充值
      visibles: false, //修改密码
      visiblen: false, //修改姓名
      visiblefw: false, //充值服务费
      visibledj: false, //冻结额度
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      djyuanyin: "", // 冻结原有
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 100, // 每页显示条数
      yingshou: "", //收款金额
      zhifu: "", //充值金额
      fuwu: "", //充值服务费
      imge: "",
      total_money: "", //总易货额
      total_moneyfee: "", //总服务费
      shopname: "",
      cardid: "",
      fangshi: "", //结算方式
      kaname: "",
      money_order: "", //额度排序
      moneyfee_order: "", //服务费排序
      order_id: "",
      dptype: "",
      options: [
        {
          value: "选项1",
          label: "新开卡",
        },
        {
          value: "选项2",
          label: "纠错",
        },
        // {
        //   value: "选项3",
        //   label: "微信",
        // },
        // {
        //   value: "选项4",
        //   label: "现金",
        // },
      ],
      newlist: [],
      timer: "", //计时器
      pwidth: 0, //公告文本的宽度
      value: 0,
      gg: "",
      i: "",
      titleStr: "",
      // windowWidth:'', // box宽度
      windowWidth: document.documentElement.clientWidth / 2.5,
      djmoney: "",
    };
  },
  mounted() {
    let element = this.$refs.cmdlist;
    this.pwidth = document.defaultView
      .getComputedStyle(element, "")
      .width.split("px");
    this.timer = setInterval(this.clickCommend, 20);
    this.getUser();
    this.shopname = localStorage.getItem("shopname");
    this.imge = localStorage.getItem("imge");
    var type_id = localStorage.getItem("type_id");
    if (type_id == 3) {
      this.dptype = "易货店";
    } else if (type_id == 5) {
      this.dptype = "折扣仓";
    }
    this.$axios.post(this.$api.new, {}).then((res) => {
      if (res.code == 1) {
        this.newlist = res.data;
        this.gg = this.newlist[0].title;
        this.i = 0;
      }
    });

    // this.$axios.post(this.$api.shopinfo, {}).then((res) => {
    //   if (res.code == 1) {
    //     console.log(res.data.shop_name);
    //     this.shopname = res.data.shop_name;
    //     this.imge = res.data.logo;
    //     // this.sdyingshou = res.data.total_price
    //     // this.sdshishou = res.data.total_price
    //   }
    // });
  },
  watch: {
    value(newValue) {
      let allWidth = parseInt(this.windowWidth) + parseInt(this.pwidth[0]);
      // console.log(oldValue);
      if (newValue <= -allWidth) {
        this.$refs.cmdlist.style.marginLeft = this.windowWidth + "px";
        this.value = 0;
      }
    },
  },
  methods: {
    inputc(e) {
      e.target.readOnly = false
    },
    handleExcel() {
      var shop_id = localStorage.getItem("shop_id");
      let fields = {
        title0: "ID",
        title1: "卡号",
        title2: "余额",
        title3: "类型",
        title4: "服务费",
        title5: "时间",
        title6: "电话",
        title7: "姓名",
        title8: "网点",
      };
      let arry = [];
      let params = {};
      let cardtype;
      params.page = 1;
      params.pageSize = 999; //这里也可以直接通过接口返回的total，拿值
      this.$axios
        .post(this.$api.daocard, {
          cardnum: this.cardinput, // 查询参数
          account: this.input,
          nickname: this.nameinput,
          shop_id: shop_id.toString(),
          start_time: this.start,
          end_time: this.end,
        })
        .then((res) => {
          console.log(res);
          res.data.forEach((ele) => {
            if (ele.card_type == 3) {
              cardtype = "易货卡";
            } else if (ele.card_type == 5) {
              cardtype = "折扣卡";
            }
            arry.push({
              title0: ele.id,
              title1: ele.cardnum,
              title2: ele.money,
              title3: cardtype,
              title4: ele.moneyfee,
              title5: ele.ttime,
              title6: ele.account,
              title7: ele.nickname,
              title8: ele.shop_name,
            });
          });
          console.log(arry);
          console.log(fields);
          this.$xlsx.xlsx(arry, fields, "excel统计列表");
        });
    },
    clickCommend() {
      // console.log(e);
      let _this = this;
      this.$nextTick(() => {
        this.value -= 1;
        this.$refs.cmdlist.style.marginLeft =
          _this.windowWidth + this.value + "px";
      });
    },
    menter() {
      clearInterval(this.timer);
    },
    mleave() {
      this.timer = setInterval(this.clickCommend, 20);
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    detail(i) {
      this.$router.push({
        name: "proinfo",
        params: {
          details: this.newlist[i].details,
          title: this.newlist[i].title,
        },
      });
    },
    stateFormat(row) {
      if (row.card_type === 3) {
        return "易货卡";
      } else if (row.card_type === 5) {
        return "折扣卡";
      }
    },
    stateFormatk(row) {
      if (row.open_type === 1) {
        return "客户卡";
      } else if (row.card_type === 2) {
        return "内部卡";
      } else if (row.card_type === 3) {
        return "赠送卡";
      }
    },
    stateFormats(row) {
      console.log(row.qx);
      if (row.qx === 4) {
        return "已冻结";
      } else {
        return "无冻结";
      }
    },
    changeColor() {
      // if (this.smfee == "扫码") {
      this.smfee = "";
      // } else {
      // this.smfee = this.smfee;
      // }
    },
    searchEnterFunpay: function (e) {
      var keyCode = window.event ? e.keyCode : e.which;
      if (keyCode == 13) {
        this.$axios
          .post(this.$api.addfw, {
            id: this.cardid,
            service_fee: this.fuwu,
            auth_code: this.smfee, //扫码
          })
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              //判断是否结算成功
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.order_id = res.data.order_id;
              Loading.service({
                fullscreen: true,
                text: "正在查询支付结果...",
                background: "rgba(0, 0, 0, 0.7)",
              });
              let timer = setInterval(() => {
                this.checkpay(timer);
              }, 2000);
            } else {
              this.smfee = "";
              console.log("sm");
              // this.$message({
              //   message: res.msg,
              //   type: "warning",
              // });
            }
          });
      }
    },
    checkpay(timer) {
      console.log(this.order_id);
      setTimeout(() => {
        this.$axios
          .post(
            this.$api.chapayfee,
            {
              order_id: this.order_id,
            },
            false
          )
          .then((res) => {
            if (res.code == 1) {
              if (res.data.pay_status == 1) {
                clearInterval(timer);
                //  loading.close();
                this.$alert("支付成功", "消息", {
                  confirmButtonText: "完成",
                  callback: (action) => {
                    console.log(action);
                    this.visiblefw = false;
                    this.getUser();
                  },
                });
              }
            } else {
              console.log("zf");
              clearInterval(timer);
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
      }, 0);
    },
    changeSort(val) {
      console.log(val.prop);
      console.log(val.order); // column: {…} order: "ascending" prop: "date"
      if (val.prop == "money") {
        if (val.order == "ascending") {
          this.money_order = "asc";
          this.getUser();
        } else if (val.order == "descending") {
          this.money_order = "desc";
          this.getUser();
        }
      } else if (val.prop == "moneyfee") {
        if (val.order == "ascending") {
          this.moneyfee_order = "asc";
          this.getUser();
        } else if (val.order == "descending") {
          this.moneyfee_order = "desc";
          this.getUser();
        }
      }
    },
    // checkTime(i){
    //   if(i<10){
    //     i='0'+i
    //     }
    //     return i
    //     },
    getUser() {
      this.$axios
        .post(this.$api.vipcard, {
          cardnum: this.cardinput, // 查询参数
          account: this.input,
          nickname: this.nameinput,
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
          money_order: this.money_order,
          moneyfee_order: this.moneyfee_order,
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.count; // 总条目数
          this.total_money = res.data.total_money;
          this.total_moneyfee = res.data.total_moneyfee;
        });
    },

    handleClick(row) {
      console.log(row.id);
      this.visible = true;
      this.cardid = row.id;
    },
    handleClicks(row) {
      console.log(row.id);
      this.visibles = true;
      this.cardid = row.id;
    },
    handleClickdj(row) {
      console.log(row.id);
      this.visibledj = true;
      this.cardid = row.id;
    },
    handleClickn(row) {
      console.log(row.id);
      this.visiblen = true;
      this.cardid = row.id;
    },
    handleClickf(row) {
      console.log(row.id);
      this.visiblefw = true;
      this.cardid = row.id;
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
    jiesuan() {
      if (this.yuanyin.length < 10) {
        this.$message({
          message: "请输入原因不少于10个字",
          type: "warning",
        });
      } else {
        //最终商品结算
        this.$confirm("请您确定结算内容, 是否继续?", "提示", {
          //弹框确定继续操作
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            this.$axios
              .post(this.$api.addvipcard, {
                id: this.cardid,
                // collection_money: this.yingshou,
                recharge_money: this.zhifu,
                recharge_reason: this.yuanyin,
              })
              .then((res) => {
                if (res.code == 1) {
                  //判断是否结算成功
                  this.$message({
                    message: res.msg,
                    type: "success",
                  });
                  this.visible = false;
                  this.getUser();
                  // location.reload(); //刷新页面
                } else {
                  this.$message({
                    message: res.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
            this.visible = false;
          });
      }
    },
    nopay() {
      //取消结算
      this.$message({
        message: "取消结算",
        type: "warning",
      });
      this.visible = false;
    },
    defImg() {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
    xiugai() {
      //最终商品结算
      this.$confirm("请您确定修改密码, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.xiupwd, {
              id: this.cardid,
              name: this.kaname,
              password: this.onepas,
              confirm_password: this.twopas,
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visibles = false;
                this.getUser();
                // location.reload() //刷新页面
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.visibles = false;
        });
    },
    xiugain() {
      //最终商品结算
      this.$confirm("请您确定修改名字, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.xiuname, {
              id: this.cardid,
              nickname: this.kaname,
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visiblen = false;
                this.getUser();
                // location.reload() //刷新页面
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.visibles = false;
        });
    },
    jiesuanfw() {
      //充值服务费
      this.$confirm("请您确定是否充值, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          console.log("充值服务费");
          this.$axios
            .post(this.$api.addfw, {
              id: this.cardid,
              service_fee: this.fuwu,
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visiblefw = false;
                this.getUser();
                // location.reload() //刷新页面
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.visiblefw = false;
        });
    },
    dongjie() {
      this.$confirm("请您确定冻结, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.dongjie, {
              id: this.cardid,
              dongjie: this.djmoney,
              djyuanyin: this.djyuanyin
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visibledj = false;
                this.getUser();
                this.djmoney = "";
                // location.reload(); //刷新页面
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.visibledj = false;
        });
    },
    noxiu() {
      //取消结算
      this.$message({
        message: "取消修改",
        type: "warning",
      });
      this.visibles = false;
      this.visiblen = false;
      this.visiblefw = false;
      this.visibledj = false;
    },
  },
  computed: {},
};
</script>
<style scoped>
#box {
  width: 60%;
  vertical-align: middle;
  display: inline-block;
  margin-right: 5%;
  background: #50a3e5;
  color: #fff;
  white-space: nowrap;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.marquee-box {
  overflow: auto;
}

#pWidth {
  width: 60%;
  height: 50px;
  padding: 0;
  margin: 0;
  line-height: 50px;
  display: block;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  font-family: 微软雅黑;
  font-size: 14px;
}

::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.spbiao {
  width: 40%;
  left: 22%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 5%;
}

.head {
  text-align: right;
  background: #50a3e5;
  color: white;
  padding: 10px;
  margin: 15px;
}

.touimg {
  width: 45px;
  height: 45px;
  vertical-align: middle;
  border-radius: 50%;
}

.spname {
  font-size: 18px;
  display: inline-block;
  margin-left: 15px;
}

h5 {
  display: inline-block;
  width: 30%;
}

.notice {
  width: 65%;
  height: 50px;
  top: 25px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}

.notice /deep/ .el-card__body {
  padding: 0;
}

/*使文字和公告图片在一行*/
.notice_item {
  height: 50px;
  line-height: 50px;
  background: #50a3e5;
  color: white;
  white-space: nowrap;
  font-size: 14px;
  /* background-color: #fff; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notice_item img {
  width: 40px;
  /*height: 16px;*/
  margin: 0 44px 0 32px;
}

.el-card {
  border: none;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
