import axios from 'axios';
import { Message, Loading } from 'element-ui';
import { MessageBox } from 'element-ui';
import md5 from 'js-md5';
// import store from '@/store';

const options = {
  crossDomain: true,
  headers: {}
};
axios.defaults.withCredentials = true;

const xhr = {
  get(url, params, loading = true) {
    params["timestamp"] =  Date.parse(new Date()).toString().substr(0, 10);
    var res = Object.keys(params).sort();
    var a=[]
    for (var key in res) {
      console.log("key: " + res[key] + " ,value: " + params[res[key]]);
      if(params[res[key]]!=""&&params[res[key]]!=undefined){
        a.push(res[key] + "=" + params[res[key]])
      }
    }
    var sign=md5(a.join('&')+"&secret=XGMsCPW72zTH1vFgNmUKGpUc").toUpperCase()
    axios.defaults.headers.common['sign'] = sign
    axios.defaults.headers.common['token'] = localStorage.getItem("token")
    // let loading = Loading.service({
    //   fullscreen: true,
    //   text: '拼命加载中...',
    //   background: 'rgba(0, 0, 0, 0.7)'
    // });
    let load = this.loading(loading);
    return new Promise((resolve, reject) => {
      axios.create(options).get(url, { params }).then(res => {
        // loading.close();
        this.close(load);
        if (res.data.code !== 1) {
          switch (res.data.code) {
            case 0:
              Message({
                message: res.data.msg,
                type: 'error',
                duration: 2 * 1000
              });
              break;
            default:
              reject(res.data);
          }
        } else {
          resolve(res.data);
        }

      }).catch(err => {
        // loading.close();
        this.close(load);
        console.log(err.response.data.msg)
        switch (err.response.status) {
          case 401:
            MessageBox.alert(err.response.data.msg, '系统提示', {
              showClose:false,
              confirmButtonText: '确定',
              callback: action => {
                if(action === 'confirm' ){
                  localStorage.clear();
                  window.location.href = window.location.origin
                }
              }
            });
            break;
          case 500:
            Message({
              message: err.response.data.msg,
              type: 'error',
              duration: 2 * 1000
            });
            break;
          default:
            reject(err);
            break;
        }

      });
    });
  },
  post(url, params, loading = true) {

    params["timestamp"] =  Date.parse(new Date()).toString().substr(0, 10);
    var res = Object.keys(params).sort();
    var a=[]
    for (var key in res) {
      if(typeof(params[res[key]])=="object"){
        params[res[key]] = JSON.stringify(params[res[key]]).replace(/[\\]/g,'')
        console.log(params[res[key]])
      }
      if(params[res[key]]!=""&&params[res[key]]!=undefined){
        a.push(res[key] + "=" + params[res[key]])
      }
     
    }
    var sign=md5(a.join('&')+"&secret=XGMsCPW72zTH1vFgNmUKGpUc").toUpperCase()
    axios.defaults.headers.common['sign'] = sign
    axios.defaults.headers.common['token'] = localStorage.getItem("token")
    let loads = this.loading(loading);
    console.log(a.join('&'))
     console.log(params)
    return new Promise((resolve, reject) => {
      axios.create(options).post(url, params).then(res => {
        this.close(loads);
        if (res.data.code == 1) {
          resolve(res.data);
        } else {
          switch (res.data.code) {
            case 0:
              Message({
                message: res.data.msg,
                type: 'error',
                duration: 2 * 1000
              });
              break;
            default:
              reject(res.data);
          }
          resolve(res.data);
        }
      }).catch(err => {
        console.log(err.response)
        this.close(loads);
        console.log(err.response.status)
        switch (err.response.status) {
          case 401:
            MessageBox.alert(err.response.data.msg, '系统提示', {
              showClose:false,
              confirmButtonText: '确定',
              callback: action => {
                if(action === 'confirm' ){
                  localStorage.clear();
                  window.location.href = window.location.origin
                }
              }
            });
            break;
          case 500:
            Message({
              message: err.response.data.msg,
              type: 'error',
              duration: 2 * 1000
            });
            break;

          default:
            reject(err);
        }
      });
    });
  },
  loading(show = true) {
    let loading = '';
    if (show == true) {
      loading = Loading.service({
        fullscreen: true,
        text: '拼命加载中...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    }
    return loading;
  },
  close(loading = '') {
    if (loading !== '') {
      loading.close();
    }
  }
};

export default xhr;
