/**   
 * api接口统一管理
 */
// const url=window.location.protocol

// const url=window.location.protocol+'//shouyin.zhilongyihuo.com'
  const url='https://shouyin.zhilongyihuo.com'//线上
//  const url='http://www.sy.cc'//本地

 const http = {
    login:url+'/index/index/login',//登录
    shopinfo:url+'/index/home/shopinfo',//店铺信息
    getUser:url+'/index/goods/orderList',//明细订单列表
    kahao:url+'/index/member/findCard',//卡号查询
    tuihuo:url+'/index/goods/refund',//退货
    handleClick:url+'/index/goods/orderView',//明细订单商品详情
    lastOrder:url+'/index/goods/lastOrder',//明细订单商品详情
    gua:url+'//index/goods/storeGoods',//挂单
    // qu:url+'//index/goods/takeGoods',//取单
    qu:url+'//index/goods/getGoods',//取单
    mima:url+'/index/member/checkCardPass',//验证密码
    bianma:url+'/index/goods/find',//输入编码查询商品
    huiyuan:url+'/index/member/info',//会员信息
    // jiesuan:url+'/index/goods/settlement',//商品结算
    jiesuan:url+'/index/settlement/goods',//商品结算
    qushop:url+'/index/goods/delStoreGoods',//取单选中商品
    vipcard:url+'/index/card/list',//会员卡列表
    addvipcard:url+'/index/card/recharge',//会员卡充值
    xiupwd:url+'/index/card/modifyPass',//修改会员卡密码
    xiuname:url+'/index/card/modify',//修改会员卡名称
    cardinfo:url+'/index/card/rechargeLog',//会员卡充值记录
    xiaofei:url+'/index/goods/orderGoodsList',//消费记录
    splist:url+'/index/goods/list',//商品列表
    huizong:url+'/index/home/statistics',//数据统计
    kainfo:url+'/index/member/cardInfo',//卡号信息
    pay:url+'/index/goods/settlementWithCash',//微信扫码
    chapay:url+'/index/settlement/checkPay',//扫码支付结果
    addfw:url+'/index/card/serviceFeeRecharge',//服务费充值
    fwlist:url+'/index/card/serviceFeeRechargeLog',//服务费充值记录
    chapayfee:url+'/index/card/checkPay',//扫码支付服务费结果
    bank:url+'//index/bankcard/list',//银行卡列表
    addbank:url+'/index/bankcard/add',//添加银行卡
    delbank:url+'/index/bankcard/delete',//删除银行卡
    tixian:url+'/index/withdrawal/apply',//提现
    xjlist:url+'/index/Withdrawal/list',//提现记录
    qus:url+'/index/help/question',//常见问题
    gooslist:url+'/index/goods/cateList',//商品分类
    new:url+'/index/help/announcement',//公告
    yuyue:url+'/index/settlement/reserveList',//预约单
    yuyueka:url+'/index/settlement/userCard',//预约人卡号
    queren:url+'/index/settlement/confirmDeal',//确认预约单结算
    out:url+'/index/goods/saleList',//商品出库详情
    diao:url+'/index/goods/dbList',//商品调拨详情
    tui:url+'/index/goods/refundList',//商品退货记录
   //  spname:urldb+'/index.php?s=/mcenter/member/getdatas',//搜索下拉显示商家
   zhexian:url+'/index/linechart',//折线图
   bing:url+'/index/piechart',//饼状图
   spcz:url+'/index/goods/oplist',//商品操作
   daogoods:url+'/index/goods/export',//商品导出
   dongjie:url+'/index/card/freezeMoney',//冻结
   linelist:url+'/index/goods/mobileOrder',//线上订单
   fahuo:url+'/index/goods/deliver',//发货
   daocard:url+'/index/card/export',//卡号导出
 }
 export default http;