<template>
  <div class="con" v-show="aa == 1">
    <h2>置隆欢迎你&nbsp; <i class="el-icon-mouse"></i></h2>
    <br /><br />
    <i class="el-icon-user-solid"></i>&nbsp; <label> 工号:</label>
    <el-input placeholder="请输入内容" v-model="name"  autocomplete="new-password" clearable style="width: 450px">
    </el-input
    ><br /><br /><br />
    <i class="el-icon-view"></i>&nbsp; <label> 密码:</label>
    <el-input
      placeholder="请输入密码"
      v-model="pwd"
      show-password
      style="width: 450px"
       autocomplete="new-password"
    ></el-input
    ><br /><br /><br />
    <el-button type="success" @click="login" class="logn">登录</el-button>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      name: "",
      pwd: "",
      aa: 1,
    };
  },
  mounted() {
    var ua = window.navigator.userAgent.toLowerCase();
    // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
    if (ua.indexOf("micromessenger") > -1) {
      this.aa = 2;
      this.$message({
        message: "请用浏览器打开",
        type: "warning",
      });
    } else {
      this.aa = 1;
      console.log("浏览器");
    }
    if (!!window.ActiveXObject || "ActiveXObject" in window) {
      this.aa = 2;
      this.$message({
        message: "请用其他浏览器打开",
        type: "warning",
      });
    } else {
      this.aa = 1;
      console.log("no");
      console.log(localStorage.getItem("username"));  
      this.name=localStorage.getItem("username")
    }
  },
  methods: {
    login() {
      console.log(this.name)
      // console.log(this.pwd)
      // if(this.name==''){
      //    this.$message({
      //   message: "请输入帐号",
      //   type: "warning",
      // });
      // }
      //  if(this.pwd==''){
      //    this.$message({
      //   message: "请输入密码",
      //   type: "warning",
      // }); 
      // }
      this.$axios
        .post(this.$api.login, {
          username: this.name,
          password: this.pwd,
        })
        .then((res) => {
          if (res.code == 1) {
            console.log(this.name)
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("shop_id", res.data.shop_id);
            localStorage.setItem("type_id", res.data.type_id);
            localStorage.setItem("user_id", res.data.user_id);
            localStorage.setItem("username",this.name);
            localStorage.setItem("password",this.pwd);
            this.$router.replace({
              name: "pagemenu",
            });
          }
        });
    },
  },
  computed: {},
};
</script>
<style scoped>
.con {
  width: 80%;
  margin: 15% auto;
}

.logn {
  width: 150px;
  height: 50px;
  font-size: 26px;
}

label {
  margin-right: 20px;
}
</style>
