import Vue from 'vue'
import Router from 'vue-router'//零售收银
import retail from './views/retail.vue'
import ElementUI from 'element-ui'
import login from './views/login.vue'//登录
import total from './views/total.vue'//汇总
import shopinfo from './views/shopinfo.vue'//商品销售表
import pagemenu from './views/pagemenu.vue'//导航目录
import vipcard from './views/vipcard.vue'//会员卡列表
import splist from './views/splist.vue'//商品列表
import vipcardinfo from './views/vipcardinfo.vue'//会员卡充值列表
import feelist from './views/feelist.vue'//会员服务费充值列表
import appointment from './views/appointment.vue'//预约订单
import bankcard from './views/bankcard.vue'//添加提现银行卡
import dcash from './views/dcash.vue'//提现记录
import pro from './views/pro.vue'//常见问题
import proinfo from './views/proinfo.vue'//问题内容
import news from './views/news.vue'//历史公告
import diaolist from './views/diaolist.vue'//调货详情列表
import outlist from './views/outlist.vue'//出货详情列表
import tuihuo from './views/tuihuo.vue'//退货详情列表
import charts from './views/charts.vue'//统计图表
import linelist from './views/linelist.vue'//线上订单
Vue.use(ElementUI)
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: login
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/retail',
      name: 'retail',
      component: retail
    },
    {
      path: '/tuihuo',
      name: 'tuihuo',
      component: tuihuo
    },
    {
      path: '/pro',
      name: 'pro',
      component: pro
    },
    {
      path: '/news',
      name: 'news',
      component: news
    },
    {
      path: '/linelist',
      name: 'linelist',
      component: linelist
    },
    {
      path: '/outlist',
      name: 'outlist',
      component: outlist
    },
    {
      path: '/diaolist',
      name: 'diaolist',
      component: diaolist
    },
    {
      path: '/proinfo',
      name: 'proinfo',
      component: proinfo
    },
    {
      path: '/appointment',
      name: 'appointment',
      component: appointment
    },
    {
      path: '/bankcard',
      name: 'bankcard',
      component: bankcard
    },
    {
      path: '/dcash',
      name: 'dcash',
      component: dcash
    },
    {
      path: '/feelist',
      name: 'feelist',
      component: feelist
    },
    {
      path: '/shopinfo',
      name: 'shopinfo',
      component: shopinfo
    },
    {
      path: '/splist',
      name: 'splist',
      component: splist
    }, {
      path: '/pagemenu',
      name: 'pagemenu',
      component: pagemenu
    },
    {
      path: '/vipcard',
      name: 'vipcard',
      component: vipcard
    },
    {
      path: '/vipcardinfo',
      name: 'vipcardinfo',
      component: vipcardinfo
    }, {
      path: '/total',
      name: 'total',
      component: total
    },
    {
      path: '/charts',
      name: 'charts',
      component: charts
    },
    {
      path: '/orderinfo',
      name: 'orderinfo',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/orderinfo.vue')//订单明细
    }
  ]
})
